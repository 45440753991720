/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { useGender, useOrganization } from '@hc/store';
import {
  Button,
  Drawer,
  Dropdown,
  Input,
  Label,
  MobileInput,
} from '@hc/ui/atoms';
import { queryClient } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { addMembersDrawer_style } from './style';

function AddMembersDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    memberDrawerOpen,
    closeMemberDrawer,
    ...rest
  } = props;

  const {
    handleMemberChange,
    getAllOrganizationMembers,
    getMemberCount,
    clearMemberData,
    upsertMember,
    organizationState,
    loading,
  } = useOrganization((state) => ({
    clearMemberData: state.clearMemberData,
    upsertMember: state.upsertMember,
    getAllOrganizationMembers: state.getAllOrganizationMembers,
    getMemberCount: state.getMemberCount,
    handleMemberChange: state.handleMemberChange,
    organizationState: state.organizationState,
    loading: state.loading,
  }));

  const { members } = organizationState;
  const { member } = members;
  const { error } = member;

  const { genderData, getGenderDetails } = useGender((state) => ({
    genderData: state.genderData,
    getGenderDetails: state.getGenderDetails,
  }));

  const newgenderData = genderData.filter(
    (filter) => filter.label !== 'transgender',
  );

  let newContract_Count = [];
  for (const object of members?.contract_Count) {
    if (object.remaining_member_count > 0) {
      let obj = {
        label: `${object.contract_id} (${object.remaining_member_count})`,
        value: object.id,
      };
      newContract_Count.push(obj);
    }
  }

  // const { organization } = organizationState;
  // const { error } = organization;

  const isIamValidToSave = () => {
    const memberCopy = JSON.parse(JSON.stringify(members?.member));
    let isValid = true;
    const error = memberCopy?.error;
    // checking name
    if (!members?.member?.name) {
      isValid = false;
      error.name = 'Please enter your name';
    } else {
      error.name = '';
    }

    // checking email
    const filter = /\S+@\S+\.\S+/;
    if (
      members?.member?.email?.length === 0 &&
      !filter.test(memberCopy?.email)
    ) {
      isValid = false;
      error.email = 'Please enter the valid mail';
    } else {
      error.email = '';
    }

    // Checking Mobile Number
    if (
      members?.member?.mobileNumber?.mobile?.length === 0 ||
      members?.member?.mobileNumber?.mobile === undefined
    ) {
      isValid = false;
      error.mobile = 'Please enter the mobile number';
    } else if (
      members?.member?.mobileNumber?.mobile?.length > 0 &&
      members?.member?.mobileNumber?.mobile?.length < 10
    ) {
      isValid = false;
      error.mobile = 'Enter a valid 10 digit mobile number';
    } else {
      error.mobile = '';
    }
    // Checking contract info
    if (!members?.member?.contract_id) {
      isValid = false;
      error.contract_id = 'Please select contract';
    } else {
      error.contract_id = '';
    }
    handleMemberChange('error', error);
    return isValid;
  };
  const upsertOrganizationMaster = async () => {
    const result = isIamValidToSave();
    if (result) {
      queryClient.invalidateQueries({
        queryKey: ['members/add'],
      });
      const response = await upsertMember();

      if (response?.status === 200) {
        closeMemberDrawer();
        queryClient.invalidateQueries({
          queryKey: ['organization-members-all'],
        });
        await getAllOrganizationMembers('', 10, 0);
        getMemberCount();
        clearMemberData();
      }
    }
  };

  const getInitialData = async () => {
    await getGenderDetails();
    queryClient.invalidateQueries({
      queryKey: ['organization-members-all'],
    });
    if (organizationState?.organization?.id) {
      queryClient.invalidateQueries({
        queryKey: ['organization-members-all'],
      });
      await getMemberCount();
      await getAllOrganizationMembers('', 10, 0);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Box
      sx={{
        ...addMembersDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {memberDrawerOpen && (
        <Drawer
          show={memberDrawerOpen}
          onCloseDrawer={closeMemberDrawer}
          anchor="right"
          isCloseIconRequired
          header="Member"
          headerStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#101010',
            textTransform: 'capitalize',
          }}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                loading={loading}
                buttonStyle={{
                  bgcolor: 'primary.main',
                  textTransform: 'capitalize',
                  color: '#fff',
                  borderRadius: '8px',
                  '&:hover': {
                    bgcolor: 'primary.main',
                  },
                }}
                onClick={() => upsertOrganizationMaster()}
              >
                Save
              </Button>
              <Button
                disabled={loading === true ? true : false}
                buttonStyle={{
                  border: '1px solid',
                  borderColor: 'primary.main',
                  color: 'primary.main',
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  bgcolor: '#fff',
                  '&:hover': {
                    bgcolor: 'primaryTints.200',
                  },
                }}
                onClick={closeMemberDrawer}
              >
                Cancel
              </Button>
            </Box>
          }
        >
          <Box sx={addMembersDrawer_style.boxRootSx}>
            <Box sx={addMembersDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addMembersDrawer_style.labelSx}
                htmlFor="organizationName"
                isRequired
              >
                Name
              </Label>
              <Input
                id="organizationName"
                textFieldStyle={addMembersDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleMemberChange('name', e.target.value, 20);
                }}
                isError={error?.name?.length > 0}
                errorMessage={error?.name}
                value={members?.member?.name}
                fullWidth
              />
            </Box>
            <Box sx={addMembersDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addMembersDrawer_style.labelSx}
                htmlFor="organizationEmail"
                isRequired
              >
                Email
              </Label>
              <Input
                id="organizationEmail"
                textFieldStyle={addMembersDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleMemberChange('email', e.target.value, 20);
                }}
                isError={error?.email?.length > 0}
                errorMessage={error?.email}
                value={members?.member?.email}
                fullWidth
              />
            </Box>

            <Box sx={addMembersDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addMembersDrawer_style.labelSx}
                htmlFor="organizationEmail"
                isRequired
              >
                Phone Number
              </Label>
              <MobileInput
                value={{
                  mobile_code:
                    members?.member?.mobileNumber?.mobileCode ?? '+91',
                  mobile: members?.member?.mobileNumber?.mobile ?? '',
                }}
                isError={error?.mobile?.length > 0}
                helperText={error?.mobile}
                onChange={(value) => handleMemberChange('mobileNumber', value)}
              />
            </Box>
            <Box>
              <Label
                rootStyle={addMembersDrawer_style.labelSx}
                htmlFor="mobileNumber"
                isRequired
              >
                contract
              </Label>
              <Dropdown
                datatestid="contract"
                value={members?.member?.contract_id}
                onChange={(e) =>
                  handleMemberChange('contract_id', e.target.value)
                }
                selectOption={newContract_Count}
                isError={error?.contract_id?.length > 0}
                helperText={error?.contract_id}
              />
            </Box>
            <Box sx={addMembersDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addMembersDrawer_style.labelSx}
                htmlFor="organizationEmail"
              >
                Gender
              </Label>

              <Box sx={addMembersDrawer_style.inputGroupSx}>
                <Box sx={addMembersDrawer_style.genderSx}>
                  {newgenderData?.map((val, index) => (
                    <Box
                      key={index}
                      sx={{
                        ...addMembersDrawer_style.rootchildrenSx,
                        ...(val?.value === members?.member?.gender?.value
                          ? addMembersDrawer_style.selectAvailabilityButtonSx
                          : addMembersDrawer_style.unSelectAvailabilityButtonSx),
                      }}
                      id={`gender${index}`}
                      onClick={() => handleMemberChange('gender', val)}
                    >
                      <Box
                        sx={{
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            p: 1.25,
                          }}
                        >
                          {val?.label}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

AddMembersDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { AddMembersDrawer };
