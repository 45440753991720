import { getDateFormatToString } from '@hc/dayjs';
import { usePlanManagement } from '@hc/store/backoffice/planManagement';
import { EditIcon, Input, Label, Switch, ToggleButton } from '@hc/ui/atoms';
import { TableSkeletonLoader } from '@hc/ui/components';
import { MasterTable, MasterTopContainer } from '@hc/ui/components/backoffice';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { PlanManagementDrawer } from '@hc/ui/components/backoffice/planManagementDrawer';
import { queryClient } from '@hc/utils';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { PlanManagement_style } from './style';

export default function PlanManagement(props) {
  const { className = '', ...rest } = props;

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [planManagementDrawerOpen, setPlanManagementDrawerOpen] =
    useState(false);
  const [saveBtn, setSaveBtn] = useState(false);
  const options = [
    {
      value: 'Appointment',
      name: 'Appointment',
      selected: false,
    },

    {
      value: 'Followup',
      name: 'Followup',
      selected: false,
    },
  ];

  // Store Data

  const {
    planManagementState,
    getAllPlanManagementData,
    planManagemenStatusUpdate,
    initialEditPlanManagementData,
    clearPlanManagementData,
    handlePlanManagementFormChange,
    isIamValidToSave,
    upsertPlanManagement,
    loading,
  } = usePlanManagement((state) => ({
    planManagementState: state.planManagementState,
    getAllPlanManagementData: state.getAllPlanManagementData,
    planManagemenStatusUpdate: state.planManagemenStatusUpdate,
    initialEditPlanManagementData: state.initialEditPlanManagementData,
    clearPlanManagementData: state.clearPlanManagementData,
    handlePlanManagementFormChange: state.handlePlanManagementFormChange,
    isIamValidToSave: state.isIamValidToSave,
    upsertPlanManagement: state.upsertPlanManagement,
    loading: state.loading,
  }));

  const { planManagementData, planManagement } = planManagementState;

  // General Hooks

  const closePlanManagementDrawer = () => {
    setPlanManagementDrawerOpen(false);
    setSaveBtn(false);
  };

  const giveMeAmountInWords = () => {
    if (planManagement.amount > 0) {
      return `₹ ${planManagement.amount / 100} /- ( One Rupees Only)`;
    }
    return '';
  };

  // searchValue IS THE PARAM
  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    queryClient.invalidateQueries({
      queryKey: ['getAllPlanManagementData'],
    });
    await getAllPlanManagementData(searchValue);
    setOffset(0);
  };
  const handleAddClick = () => {
    clearPlanManagementData();
    setPlanManagementDrawerOpen(true);
  };

  const onStatusChange = async (id, status) => {
    const payload = {
      id,
      status: !status,
    };
    const response = await planManagemenStatusUpdate(payload);
    if (response === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getAllPlanManagementData'],
      });
      await getAllPlanManagementData(search, rowsPerPage, offset);
    }
  };

  const onEdit = (data) => {
    initialEditPlanManagementData(data);
    setPlanManagementDrawerOpen(true);
  };

  const columns = [
    {
      field: 'plan_name',
      headerName: 'Plan Name',
      width: 280,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 140,
      renderCell: (params) => {
        // let amountData = params.row.amount / 100;
        return (
          <Tooltip title={`₹ ${params?.row?.amount_in_rupees}/-  `}>
            <Typography variant="body2">
              {`₹ ${params?.row?.amount_in_rupees} /-`}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'plan_type',
      headerName: 'Plan Type',
      width: 160,
    },
    {
      field: 'doctor_mapped',
      headerName: 'Doctor Mapped',
      width: 160,
    },
    {
      field: 'is_active',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        <Switch
          id="toggle3"
          checked={params?.row?.is_active}
          onChange={() =>
            onStatusChange(params?.row?.id, params?.row?.is_active)
          }
          rootStyle={PlanManagement_style.switchSx}
        />
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <Box sx={{ pl: 1 }} onClick={() => onEdit(params?.row)}>
          <EditIcon id="edit3" style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllPlanManagementData'],
    });
    await getAllPlanManagementData(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllPlanManagementData'],
    });
    await getAllPlanManagementData(search, rowsPerPage, offset);
  };

  const initialData = async () => {
    await getAllPlanManagementData('');
  };

  const handlePlanformChange = (key, value) => {
    handlePlanManagementFormChange(key, value);
    if (saveBtn) isIamValidToSave();
  };

  // UPSERT EXPERT FUNCTION
  const upsertPlanManagementFnc = async () => {
    const result = isIamValidToSave();
    setSaveBtn(true);
    if (result) {
      setSaveBtn(false);
      const reponse = await upsertPlanManagement();
      if (reponse === 200) {
        queryClient.invalidateQueries({
          queryKey: ['getAllPlanManagementData'],
        });
        initialData();
        setPlanManagementDrawerOpen(false);
        clearPlanManagementData();
      }
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      px={3}
      py={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MasterTopContainer
              addMasterStyle={PlanManagement_style.addMasterSx}
              handleSearch={handleSearch}
              handleAddClick={handleAddClick}
              title="Plan Management"
              count={planManagementData?.count ?? '0'}
              showSearchField
              searchFieldPlaceholder="Search Plan Name"
              showAddButton
              addButtonName="Add Plans"
            />
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Box>
                <TableSkeletonLoader />
              </Box>
            ) : (
              <Box>
                <MasterTable
                  columns={columns}
                  data={
                    Array.isArray(planManagementData?.data) &&
                    planManagementData?.data?.length
                      ? planManagementData?.data
                      : []
                  }
                  customTableStyle={PlanManagement_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              </Box>
            )}
          </Grid>
          <Stack
            flexDirection="row"
            justifyContent="end"
            sx={{ width: '100%' }}
          >
            <Pagination
              totalCount={planManagementData?.count}
              onLimitChange={onLimitChange}
              onOffsetchange={onOffsetchange}
              offset={offset}
              rowsPerPage={rowsPerPage}
            />
          </Stack>
        </Grid>

        {/* Add Plan Management Drawer */}
        <Box>
          <PlanManagementDrawer
            isUpdate={planManagement?.id?.length > 0 ? true : false}
            drawerOpen={planManagementDrawerOpen}
            loading={loading}
            header="Plan Management"
            closeDrawer={closePlanManagementDrawer}
            upsertUser={upsertPlanManagementFnc}
            // eslint-disable-next-line react/no-children-prop
            children={
              <Box width={328}>
                <Grid>
                  <Grid pb={1.5} item md={12} lg={12} xl={12}>
                    <Label isRequired htmlFor="name">
                      Plan Name
                    </Label>
                    <Input
                      id="plan_name"
                      placeholder="Plan Name"
                      isError={planManagement?.error?.plan_name?.length > 0}
                      errorMessage={planManagement?.error?.plan_name}
                      onChange={(event) =>
                        handlePlanformChange('plan_name', event?.target.value)
                      }
                      value={planManagement?.plan_name}
                    />
                  </Grid>
                  <Grid pb={1.5} item md={12} lg={12} xl={12}>
                    <Label isRequired htmlFor="email">
                      Amount (In Paisa)
                    </Label>
                    <Input
                      id="amount"
                      placeholder="Amount"
                      isError={planManagement?.error?.amount?.length > 0}
                      errorMessage={planManagement?.error?.amount}
                      onChange={(event) =>
                        handlePlanformChange('amount', event?.target.value)
                      }
                      value={planManagement?.amount}
                      type="number"
                    />
                    <Typography variant="caption" color="textSecondary">
                      {giveMeAmountInWords()}
                    </Typography>
                  </Grid>

                  <Grid pb={1.5} item md={12} lg={12} xl={12}>
                    <Label isRequired>Plan type</Label>

                    <ToggleButton
                      options={options}
                      value={planManagement?.plan_type}
                      onChange={(e) => {
                        handlePlanformChange('plan_type', e);
                      }}
                      buttonactiveStyle={
                        PlanManagement_style.togglebuttonactiveSX
                      }
                      buttoninactiveStyle={
                        PlanManagement_style.togglebuttoninactiveSX
                      }
                    />

                    {planManagement?.error?.plan_type && (
                      <Typography color="#F44F5A" mt={0.5} fontSize={12}>
                        {planManagement?.error?.plan_type}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            }
          />
        </Box>
      </Box>
    </Box>
  );
}

PlanManagement.propTypes = {
  className: PropTypes.string,
};
