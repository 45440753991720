import { useBackofficeProfile } from '@hc/store/backoffice/onboarding';
import { Button, HealthCirclesLogo, Input, Label } from '@hc/ui/atoms';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { login_content_card_style } from './style';

export function LoginBackofficeContentCard() {
  const { user, signIn, updateOnboarding, updateErrorOnboarding, loading } =
    useBackofficeProfile(
      (state) => ({
        signIn: state.signIn,
        user: state.user,
        updateOnboarding: state.updateOnboarding,
        updateErrorOnboarding: state.updateErrorOnboarding,
        loading: state.loading,
      }),
      (prev, curr) => isEqual(prev, curr)
    );

  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };

  const handleChange = (val, key) => {
    updateOnboarding(key, val);
  };

  // Validation
  const isIamValideToSignIn = () => {
    let isValid = true;
    const error = user?.error;

    //  Checking email
    if (user?.email.length === 0) {
      isValid = false;
      error.email = 'Enter a valid email';
    } else if (user?.email.length < 10) {
      isValid = false;
      error.email = 'Enter a valid email';
    } else {
      error.email = '';
    }

    // Checking password
    if (user?.password.length === 0) {
      isValid = false;
      error.password = 'Enter password';
    } else {
      error.password = '';
    }
    updateErrorOnboarding(error);
    return isValid;
  };

  const onSignIn = async () => {
    if (isIamValideToSignIn()) {
      const error = user?.error;
      error.password = '';
      error.email = '';
      updateErrorOnboarding(error);
      // calling the signup api
      await signIn(user);
    }
  };

  return (
    <Box sx={login_content_card_style.CardcontentSx}>
      <Box sx={login_content_card_style.logowithtextSx}>
        <IconButton disableRipple>
          <HealthCirclesLogo rootStyle={login_content_card_style.hcLogoSx} />
        </IconButton>
        <Typography sx={login_content_card_style.companytypoSx}>
          Health Circles
        </Typography>
      </Box>

      <Typography sx={login_content_card_style.createPasswordSx}>
        Welcome Admin!
      </Typography>

      <Box>
        <Label htmlFor="emailInput">Email</Label>
        <Input
          value={user?.email ?? ''}
          id="email"
          isError={user?.error.email}
          helperText={user?.error.email}
          onChange={(value) => handleChange(value.target.value, 'email')}
        />
      </Box>
      <Box sx={login_content_card_style.inputGroupSx}>
        <Label htmlFor="password" isRequired>
          password
        </Label>
        <Input
          id="password"
          type={showpassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e.target.value, 'password')}
          isError={user?.error.password}
          errorMessage={user?.error.password}
          value={user?.password ?? ''}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword()}
              edge="end"
            >
              {showpassword ? (
                <MdVisibilityOff
                  htmlColor="#848484"
                  sx={login_content_card_style.eyeSx}
                />
              ) : (
                <MdVisibility
                  htmlColor="#848484"
                  sx={login_content_card_style.eyeSx}
                />
              )}
            </IconButton>
          }
        />
      </Box>
      <Box sx={{ mt: 3, display: 'grid', gap: 3 }}>
        <Button
          fullWidth
          buttonStyle={login_content_card_style.buttonStyleSx}
          onClick={() => onSignIn()}
          loading={loading}
        >
          login
        </Button>
      </Box>
    </Box>
  );
}
