/* eslint-disable no-restricted-syntax */
import { getDateFormatToString } from '@hc/dayjs';

import { backofficeRoutes } from '@hc/routes';
import { useOrganization } from '@hc/store';
import {
  BondIcon,
  EditIcon,
  RefreshIcon,
  Switch,
  UserSettingIcon,
} from '@hc/ui/atoms';
import { AddOrganizationDrawer, TableSkeletonLoader } from '@hc/ui/components';
import { MasterTable, MasterTopContainer } from '@hc/ui/components/backoffice';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganizationTemplate_style } from './style';

export default function OrganizationTemplate(props) {
  const { className = '', ...rest } = props;

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [organizationDrawerOpen, setOrganizationDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const statusColor = {
    1: '#25C460',
    2: '#fff',
    3: '#88888',
    4: '#E4BC62',
  };

  // Store Data

  const {
    getAllorganizationData,
    organizationStatusUpdate,
    organizationState,
    handleorganizationChange,
    clearOrganizationData,
    resendInvite,
    loading,
  } = useOrganization((state) => ({
    resendInvite: state.resendInvite,
    clearOrganizationData: state.clearOrganizationData,
    handleorganizationChange: state.handleorganizationChange,
    organizationState: state.organizationState,
    getAllorganizationData: state.getAllorganizationData,
    organizationStatusUpdate: state.organizationStatusUpdate,
    loading: state.loading,
  }));

  // General Hooks

  const closeOrganizationDrawer = () => {
    setOrganizationDrawerOpen(false);
  };

  const handleAddClick = async (key, value, params) => {
    if (!value) {
      const resArr = [
        {
          key: 'id',
          value: params?.row?.value?.id,
        },
        {
          key: 'name',
          value: params?.row?.value?.organization_name,
        },
        {
          key: 'email',
          value: params?.row?.value?.email_id,
        },
        {
          key: 'mobileNumber',
          value: {
            mobile: params?.row?.value?.mobile_no,
            mobileCode: params?.row?.value?.mobile_no_country_code,
          },
        },
        {
          key: 'address',
          value: params?.row?.value?.address,
        },
        {
          key: 'user_profile_id',
          value: params?.row?.value?.user_profile_id,
        },
        {
          key: 'user_id',
          value: params?.row?.value?.user_id,
        },
        {
          key: 'logo',
          value: params?.row?.value?.logo,
        },
        {
          key: 'is_active',
          value: params?.row?.value?.is_active,
        },
        {
          key: 'status',
          value: params?.row?.value?.status_name,
        },
      ];

      for (const object of resArr) {
        handleorganizationChange(object?.key, object?.value);
      }
    }
    if (key === 'edit') {
      handleorganizationChange('createOrganization', value);
      setOrganizationDrawerOpen(true);
    } else if (key === 'contract') {
      navigate(backofficeRoutes.organizationMember);
    } else if (key === 'member') {
      navigate(backofficeRoutes?.contractDetails);
    } else if (key === 'new') {
      handleorganizationChange('createOrganization', value);
      clearOrganizationData();
      setOrganizationDrawerOpen(true);
    }
  };
  // searchValue IS THE PARAM

  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    queryClient.invalidateQueries({
      queryKey: ['getAllorganizationData'],
    });
    await getAllorganizationData(searchValue);
    setOffset(0);
  };

  const onStatusChange = async (params) => {
    queryClient.invalidateQueries({
      queryKey: ['organization_status_update'],
    });
    const response = await organizationStatusUpdate(params);
    if (response === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getAllorganizationData'],
      });
      await getAllorganizationData(search, rowsPerPage, offset);
    }
  };

  const resendInviteBtn = async (params) => {
    // debugger;
    queryClient.invalidateQueries({
      queryKey: ['user-resend-invite'],
    });
    await resendInvite(params);
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1.8, cursor: 'pointer' }}>
          <EditIcon id= 'editOrganization' onClick={() => handleAddClick('edit', false, params)} />
          <UserSettingIcon id = 'addMember'
            onClick={() => handleAddClick('contract', false, params)}
          />
          <BondIcon id ='contract' onClick={() => handleAddClick('member', false, params)} />
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'members',
      headerName: 'Members',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 150,
    },

    {
      field: 'is_active',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <Typography
          variant="body1"
          sx={{
            color: statusColor[params.row.statusId],
            // backgroundColor: statusColor[params.row.statusId],
          }}
        >
          {params.row.status}
          {params.row.statusId === 4 && (
            <Tooltip title="Resend Invite">
              <IconButton
                color="secondary"
                onClick={() => resendInviteBtn(params.row)}
              >
                <RefreshIcon
                  rootStyle={{ color: statusColor[params.row.statusId] }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Switch
        id='action6'
          checked={params?.row?.is_active}
          onChange={() => onStatusChange(params)}
          rootStyle={OrganizationTemplate_style.switchSx}
        />
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },

    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },
  ];

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllorganizationData'],
    });
    await getAllorganizationData(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllorganizationData'],
    });
    await getAllorganizationData(search, rowsPerPage, offset);
  };

  const initialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['getAllorganizationData'],
    });
    await getAllorganizationData(search, rowsPerPage, 0);
  };

  useEffect(() => {
    initialData();
  }, []);
  return (
    <Box
      className={`${className}`}
      {...rest}
      px={3}
      py={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MasterTopContainer
              addMasterStyle={OrganizationTemplate_style.addMasterSx}
              handleSearch={handleSearch}
              handleAddClick={handleAddClick}
              title="Organization Management"
              count={organizationState?.organizationListCount ?? '0'}
              showSearchField
              searchFieldPlaceholder="Search by Name, Phone & Email"
              showAddButton
              addButtonName="Add Organization"
            />
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Box>
                <TableSkeletonLoader />
              </Box>
            ) : (
              <Box>
                <MasterTable
                  columns={columns}
                  data={organizationState?.organizationStateListData ?? []}
                  customTableStyle={
                    OrganizationTemplate_style.dataTableCustomSx
                  }
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              </Box>
            )}
          </Grid>
          <Stack
            flexDirection="row"
            justifyContent="end"
            sx={{ width: '100%' }}
          >
            <Pagination
              totalCount={organizationState?.organizationListCount ?? 0}
              onLimitChange={onLimitChange}
              onOffsetchange={onOffsetchange}
              offset={offset}
              rowsPerPage={rowsPerPage}
            />
          </Stack>
        </Grid>

        {/* Add Plan Template Drawer */}
        <Box>
          <AddOrganizationDrawer
            organizationDrawerOpen={organizationDrawerOpen}
            closeOrganizationDrawer={closeOrganizationDrawer}
          />
        </Box>
      </Box>
    </Box>
  );
}

OrganizationTemplate.propTypes = {
  className: PropTypes.string,
};
