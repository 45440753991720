import { useActivityDayMaster } from '@hc/store/backoffice/activityDayMaster';
import { useActivityGoalMaster } from '@hc/store/backoffice/activityGoalMaster';
import { useActivityTemplate } from '@hc/store/backoffice/activityTemplate';
import { useActivityTimeMaster } from '@hc/store/backoffice/activityTimeMaster';
import { useActivityUnitMaster } from '@hc/store/backoffice/activityUnitMaster';
import { useCategoryMaster } from '@hc/store/backoffice/categoryMaster';
import {
  ActivityDontsEditIcon,
  ActivityNotesEditIcon,
  ActivityReffernceEditIcon,
  AddDontsIcon,
  AddNotesIcone,
  AddReferencesIcon,
  AlarmClockIcon,
  Button,
  DeleteIcon,
  Drawer,
  Dropdown,
  Input,
  Label,
  ToggleButton,
  UploadIcon,
} from '@hc/ui/atoms';
import { handleUploadFile, queryClient } from '@hc/utils';
import { Box, Divider, Grid, Stack, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { DaySelector } from '../../daySelector';
import { AddDonts } from '../addDonts';
import { AddNotes } from '../addNotes';
import { AddReferences } from '../addReferences';
import { TagsToggleButton } from '../tagsToggleButton';
import { addActivityDrawer_style } from './style';

function AddActivityDrawer(props) {
  const {
    drugDrawerOpen = false,
    closeDrugMasterDrawer = () => false,
    type,
  } = props;

  const [component, setComponent] = useState(0);
  const [showUnits, setshowUnits] = useState(true);
  const [daysInString, setdaysInString] = useState('');
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);

  // ------------------------STORE---------------------
  const {
    handleActivityFormChange,
    activity,
    handleActivityNotesChange,
    updateActivityPicture,
    clearActivity,
    addEditActivityData,
    activity_references,
    handleActivitySpecificChange,
    getAllActivityTemplateData,
    clearNotes,
    clearDonts,
    clearReference,
    clearUnits,
    isIamValidToSave,
    Link,
    loading,
  } = useActivityTemplate((state) => ({
    clearNotes: state.clearNotes,
    handleActivityFormChange: state.handleActivityFormChange,
    isIamValidToSave: state.isIamValidToSave,
    activity: state.activity,
    handleActivityNotesChange: state.handleActivityNotesChange,
    updateActivityPicture: state.updateActivityPicture,
    clearActivity: state.clearActivity,
    addEditActivityData: state.addEditActivityData,
    activity_references: state.activity_references,
    handleActivitySpecificChange: state.handleActivitySpecificChange,
    getAllActivityTemplateData: state.getAllActivityTemplateData,
    clearDonts: state.clearDonts,
    clearReference: state.clearReference,
    clearUnits: state.clearUnits,
    Link: state.Link,
    loading: state.loading,
  }));

  const { categoryData } = useCategoryMaster((state) => ({
    categoryData: state.categoryData,
  }));
  const { activityUnitData } = useActivityUnitMaster((state) => ({
    activityUnitData: state.activityUnitData,
  }));
  const { activityDayData } = useActivityDayMaster((state) => ({
    activityDayData: state.activityDayData,
  }));
  const { activityTimeData } = useActivityTimeMaster((state) => ({
    activityTimeData: state.activityTimeData,
  }));

  const { activityGoalTagData } = useActivityGoalMaster((state) => ({
    activityGoalTagData: state.activityGoalTagData,
  }));

  function handleNotesChanges(content, delta, source, editor) {
    handleActivityNotesChange('activity_notes', editor.getContents());
  }

  function handleDontsChanges(content, delta, source, editor) {
    handleActivityNotesChange('activity_dont', editor.getContents());
  }

  const handleActivityPicUpload = async (e) => {
    const res = await handleUploadFile(e.target.files);
    if (
      res?.[0]?.status === 'ok' &&
      res?.[0]?.message === 'Upload Successfully!'
    ) {
      // UPDATE PROFILE PICTURE
      updateActivityPicture(res?.[0]?.downloadUrl);
      return toast.success('Activity Image Updated Successfully');
    }
  };

  const handleActivityImageDelete = async () => {
    await updateActivityPicture('');
  };

  const handleUpdateday = (newValue) => {
    if (newValue.length < 7) {
      const days = activityDayData
        .filter((val) => newValue.includes(val.value))
        .map((val) => val.description.slice(0, 3))
        .join(',');

      setdaysInString(days);
    } else setdaysInString`day`;
  };

  const HandleSubmit = async () => {
    setSaveButtonClicked(true);
    const result = isIamValidToSave();

    if (result) {
      const responseStatusCode = await addEditActivityData();

      if (responseStatusCode === 200) {
        queryClient.invalidateQueries({
          queryKey: ['getAllActivityTemplateData'],
        });

        await getAllActivityTemplateData('');
        closeDrugMasterDrawer();
        clearReference();
        clearActivity();
        setshowUnits(true);
        setSaveButtonClicked(false);
      }
    }
  };
  const handleMeasurable = (event) => {
    handleActivityFormChange('is_measurable', event.target.checked);
    if (saveButtonClicked) {
      isIamValidToSave();
    }
    setshowUnits(!showUnits);
    clearUnits();
  };

  const handleActivityFormChangeFnc = (key, value, limit) => {
    handleActivityFormChange(key, value, limit);
    if (saveButtonClicked) isIamValidToSave();
  };

  const handleDrawerClose = () => {
    if (component !== 0) {
      setComponent(0);
    } else {
      closeDrugMasterDrawer();
      clearActivity();
      setshowUnits(true);
      setSaveButtonClicked(false);
    }
  };

  const handleNotesDrawerClose = () => {
    if (activity?.activity_notes?.ops?.length > 0) {
      setComponent(0);
    } else {
      toast.error('Add some notes to save');
    }
  };
  const handleDontsDrawerClose = () => {
    if (activity?.activity_dont?.ops?.length > 0) {
      setComponent(0);
    } else {
      toast.error('Add some notes to save');
    }
  };

  const handleRefferenceDrawerClose = () => {
    if (Link[Link.length - 1].linkName && Link[Link.length - 1].url) {
      setComponent(0);
    } else {
      toast.error('Add some reference to save');
    }
  };

  const handleNotesDrawerCancle = (key) => {
    setComponent(0);
    if (key === 'Notes') clearNotes();
    if (key === 'Donts') clearDonts();
    if (key === 'Reference') clearReference();
  };

  const handleCancel = () => {
    closeDrugMasterDrawer();
    clearActivity();
    setshowUnits(true);
    setSaveButtonClicked(false);
  };
  const initialMaterData = async () => {};

  useEffect(() => {
    initialMaterData();
  }, []);

  useEffect(() => {
    handleUpdateday(activity.activity_days);
  }, []);

  return (
    <Box>
      {drugDrawerOpen && (
        <Drawer
          show={drugDrawerOpen}
          onCloseDrawer={handleDrawerClose}
          anchor="right"
          isCloseIconRequired
          header={
            component === 0
              ? type === 0
                ? 'Add Activity'
                : 'Edit Activity'
              : component === 1
              ? 'Notes'
              : component === 2
              ? 'Donts '
              : component === 3
              ? 'Reference'
              : ''
          }
          headerStyle={addActivityDrawer_style.headerSx}
          footerStyle={{ py: 2.8, px: 2 }}
          footer={
            component === 0 && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  buttonStyle={addActivityDrawer_style.SaveButtonSx}
                  onClick={HandleSubmit}
                  loading={loading}
                >
                  {type === 0 ? 'Add Activity' : 'update Activity'}
                </Button>
                <Button
                  buttonStyle={addActivityDrawer_style.cancleButtonSx}
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Box>
            )
          }
        >
          {component === 0 && (
            <Box sx={addActivityDrawer_style.boxRootSx}>
              {/* ------------ACTIVITY NAME INPUT----------- */}
              <Box sx={addActivityDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addActivityDrawer_style.labelSx}
                  htmlFor="ActivityName"
                  isRequired
                >
                  Activity Name
                </Label>
                <Input
                  placeholder="Activity Name"
                  id="ActivityName"
                  isError={activity?.error?.activity_name?.length > 0}
                  errorMessage={activity?.error?.activity_name}
                  textFieldStyle={addActivityDrawer_style.inputFieldSx}
                  onChange={(e) => {
                    handleActivityFormChangeFnc(
                      'activity_name',
                      e.target.value,
                      20,
                    );
                  }}
                  value={activity.activity_name}
                  fullWidth
                />
              </Box>

              {/*  -------------ACTIVITY IMAGE UPLOAD FIELD ----------- */}
              <Box sx={addActivityDrawer_style.inputGroupSx}>
                <Box sx={addActivityDrawer_style.LablewithBtnSx}>
                  <Label
                    rootStyle={addActivityDrawer_style.labelSx}
                    htmlFor="Activityimage"
                  >
                    Activity image
                  </Label>
                  {activity?.activity_image &&
                    activity?.activity_image?.includes('http') && (
                      <Box sx={addActivityDrawer_style.FlexSx}>
                        <DeleteIcon />
                        <Typography
                          sx={addActivityDrawer_style.ImgaeRemoveSx}
                          onClick={() => handleActivityImageDelete()}
                        >
                          &nbsp; Delete
                        </Typography>
                      </Box>
                    )}
                </Box>

                <Box sx={addActivityDrawer_style.uploadFileRootSx}>
                  {!activity?.activity_image?.includes('http') && (
                    <Box sx={addActivityDrawer_style.activeUploadFileSx}>
                      <Box
                        component="label"
                        sx={addActivityDrawer_style.uploadIconSx}
                      >
                        <UploadIcon />
                        <input
                          hidden
                          name="img"
                          accept="image/*"
                          multiple="multiple"
                          type="file"
                          id="uplodebtnv"
                          onChange={handleActivityPicUpload}
                        />
                      </Box>
                      <Box>
                        <Typography sx={addActivityDrawer_style.browseTextSx}>
                          Click to browse and upload
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  {activity?.activity_image &&
                    activity?.activity_image?.includes('http') && (
                      <Box>
                        <img
                          src={activity.activity_image}
                          alt="activity_Image"
                          style={{
                            width: '400px',
                            height: '160px',
                            objectFit: 'cover',
                            borderRadius: '10px',
                          }}
                        />
                      </Box>
                    )}
                </Box>
              </Box>
              {/*  ----------------ACTIVITY TYPE  ------------- */}
              <Box sx={addActivityDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addActivityDrawer_style.labelSx}
                  htmlFor="SelectType"
                  isRequired
                >
                  Choose plan category
                </Label>
                <ToggleButton
                  RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                  isError={activity?.error?.category_id?.length > 0}
                  errorMessage={activity?.error?.category_id}
                  options={categoryData}
                  value={activity.category_id}
                  buttonactiveStyle={
                    addActivityDrawer_style.togglebuttonactiveSX
                  }
                  buttoninactiveStyle={
                    addActivityDrawer_style.togglebuttoninactiveSX
                  }
                  onChange={(value) =>
                    handleActivityFormChangeFnc('category_id', value)
                  }
                />
              </Box>

              {/* ----------------MEASURABLE SWITCH-------------- */}
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={addActivityDrawer_style.measurableSx}
              >
                <Box sx={addActivityDrawer_style.measurableContentSx}>
                  <Box mt={0.4}>
                    {' '}
                    <AlarmClockIcon />
                  </Box>

                  <Typography pl={1}>
                    {' '}
                    Do you want to measure <br />
                    this activity?
                  </Typography>
                </Box>
                <Box>
                  {' '}
                  <Switch
                    id="toggle"
                    checked={activity.is_measurable}
                    onChange={handleMeasurable}
                    rootStyle={addActivityDrawer_style.switchSx}
                  />
                </Box>
              </Stack>

              {/* ----------------- ACTIVITY UNITS ------------- */}
              {showUnits && (
                <Box sx={addActivityDrawer_style.inputGroupSx}>
                  <Label
                    rootStyle={addActivityDrawer_style.labelSx}
                    htmlFor="Activityunits"
                    isRequired
                  >
                    Activity units
                  </Label>

                  <Grid
                    container
                    sx={addActivityDrawer_style.activityunitsSX}
                    p={1}
                  >
                    <Grid item xs={6} height="60%">
                      <Input
                        id="time"
                        placeholder="0"
                        sx={addActivityDrawer_style.textFieldStyle}
                        value={activity.quantity}
                        onChange={(e) =>
                          handleActivityFormChangeFnc(
                            'quantity',
                            e.target.value,
                          )
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      sx={{
                        borderLeft: '1px solid #E3E3E3',
                      }}
                    >
                      {' '}
                    </Grid>
                    <Grid item xs={3}>
                      <Dropdown
                        placeholder="min"
                        fullWidth={false}
                        rootStyle={addActivityDrawer_style.dropdownSX}
                        selectOption={activityUnitData}
                        value={activity.unit}
                        datatestid="Activityunits"
                        onChange={(value) => {
                          handleActivityFormChangeFnc(
                            'unit',
                            value?.target?.value,
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {activity?.error?.quantity && (
                <Typography variant="caption" color="error">
                  {activity?.error?.quantity}
                </Typography>
              )}

              {/* --------------DAY SELECTION BUTTON-------------- */}
              <Box sx={addActivityDrawer_style.containerBoxSx}>
                <Typography sx={addActivityDrawer_style.onTimeSx}>
                  Do this activity on
                </Typography>

                <DaySelector
                  buttonContent={activityDayData}
                  getDay={(newValue) =>
                    handleActivityFormChangeFnc('activity_days', newValue)
                  }
                  value={activity.activity_days}
                  onTimeChange={(newValue) => handleUpdateday(newValue)}
                />

                <Typography sx={addActivityDrawer_style.onTimeSx}>
                  {' '}
                  {`Every ${daysInString} `}
                </Typography>
                {activity?.error?.activity_days.length > 0 && (
                  <Typography variant="caption" color="error">
                    {activity?.error?.activity_days}
                  </Typography>
                )}
              </Box>

              {/* ----------TIME SELCTION TOGGLE------------------ */}

              <Box sx={addActivityDrawer_style.containerBoxSx}>
                {' '}
                <Typography sx={addActivityDrawer_style.onTimeSx}>
                  Time of activity
                </Typography>
                <ToggleButton
                  RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                  options={activityTimeData}
                  value={activity.activity_time}
                  isError={activity?.error?.activity_time?.length > 0}
                  errorMessage={activity?.error?.activity_time}
                  buttonactiveStyle={addActivityDrawer_style.buttonactivenumSX}
                  buttoninactiveStyle={
                    addActivityDrawer_style.buttoninactivenumSX
                  }
                  onChange={(value) => {
                    handleActivityFormChangeFnc('activity_time', value);
                  }}
                />
              </Box>
              {activity.activity_time === 6 && (
                <Stack
                  flexDirection="row"
                  sx={{ backgroundColor: '#F7F7F7 ', borderRadius: '10px' }}
                  pt={0.4}
                  justifyContent="space-around"
                >
                  <Box p={0.8}>
                    {' '}
                    <Typography pl={1} sx={addActivityDrawer_style.timeInput}>
                      From
                    </Typography>
                    <Input
                      type="time"
                      textFieldStyle={addActivityDrawer_style.timeinputFieldSx}
                      value={activity.specific_time.from}
                      onChange={(e) => {
                        handleActivitySpecificChange('from', e.target.value);
                      }}
                    />
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ height: '50px', mt: 1.5, mr: 2 }}
                  />

                  <Box pt={0.8}>
                    <Typography sx={addActivityDrawer_style.timeInput}>
                      To
                    </Typography>
                    <Input
                      type="time"
                      textFieldStyle={addActivityDrawer_style.timeinputFieldSx}
                      value={activity.specific_time.to}
                      onChange={(e) => {
                        handleActivitySpecificChange('to', e.target.value);
                      }}
                    />
                  </Box>
                </Stack>
              )}

              {/* -------------DRAWER BUTOONS--------------------- */}
              <Box sx={addActivityDrawer_style.notesContainerBoxSx}>
                <Box onClick={() => setComponent(1)} sx={{ cursor: 'pointer' }}>
                  {activity.activity_notes?.ops?.length > 0 ? (
                    <ActivityNotesEditIcon rootStyle={{ cursor: 'pointer' }} />
                  ) : (
                    <AddNotesIcone rootStyle={{ cursor: 'pointer' }} />
                  )}
                  <Typography sx={addActivityDrawer_style.NotesDescSx}>
                    Notes
                  </Typography>
                </Box>
                <Box onClick={() => setComponent(2)} sx={{ cursor: 'pointer' }}>
                  {activity.activity_dont?.ops?.length > 0 ? (
                    <ActivityDontsEditIcon rootStyle={{ cursor: 'pointer' }} />
                  ) : (
                    <AddDontsIcon rootStyle={{ cursor: 'pointer' }} />
                  )}
                  <Typography sx={addActivityDrawer_style.NotesDescSx}>
                    Dont&apos;s
                  </Typography>
                </Box>
                <Box onClick={() => setComponent(3)} sx={{ cursor: 'pointer' }}>
                  {Link.length > 0 && Link[0].linkName ? (
                    <ActivityReffernceEditIcon
                      rootStyle={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <AddReferencesIcon rootStyle={{ cursor: 'pointer' }} />
                  )}
                  <Typography sx={addActivityDrawer_style.NotesDescSx}>
                    reference
                  </Typography>
                </Box>
              </Box>

              {/* ------------------------------- */}
              <Box sx={addActivityDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addActivityDrawer_style.labelSx}
                  htmlFor="SelectType"
                  isRequired
                >
                  Choose plan category
                </Label>
                <TagsToggleButton
                dataTestId='goal'
                  RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                  options={activityGoalTagData}
                  value={activity.goal_id}
                  isError={activity?.error?.goal_id?.length > 0}
                  errorMessage={activity?.error?.goal_id}
                  buttonactiveStyle={
                    addActivityDrawer_style.togglebuttonactiveSX
                  }
                  buttoninactiveStyle={
                    addActivityDrawer_style.togglebuttoninactiveSX
                  }
                  valueChange={(e) => {
                    handleActivityFormChangeFnc('goal_id', e);
                  }}
                />
              </Box>
            </Box>
          )}
          {component === 1 && (
            <AddNotes
              value={activity.activity_notes}
              handleChanges={handleNotesChanges}
              HandleSubmit={handleNotesDrawerClose}
              closeNotesDrawer={() => handleNotesDrawerCancle('Notes')}
            />
          )}
          {component === 2 && (
            <AddDonts
              value={activity.activity_dont}
              handleChanges={handleDontsChanges}
              HandleSubmit={handleDontsDrawerClose}
              closeDontsDrawer={() => handleNotesDrawerCancle('Donts')}
            />
          )}
          {component === 3 && (
            <AddReferences
              reference={activity_references}
              HandleSubmit={handleRefferenceDrawerClose}
              handleChanges={handleDontsChanges}
              closeReferenceDrawer={() => handleNotesDrawerCancle('Reference')}
            />
          )}
        </Drawer>
      )}
    </Box>
  );
}

AddActivityDrawer.propTypes = {
  drugDrawerOpen: PropTypes.bool,
};

export { AddActivityDrawer };
