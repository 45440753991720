/* eslint-disable jsx-a11y/iframe-has-title */
import { AppBar, HealthCirclesLogo, SideBar } from '@atoms';
import { backofficeRoutes } from '@hc/routes';
import { localStorageKeys } from '@hc/utils';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { organizationlayout_style } from './style';
import { LogoutModal } from '../logoutModal';

export function Layout(props) {
  const {
    navItems = [],
    profileData = [],
    childrenStyle,
    bodyStyle,
    className = '',
    rootStyle,
    children,
    appBarStyle,
    Organizationlogo,
    logo,
    ...rest
  } = props;
  //   const navigate = useNavigate();
  const navigate = useNavigate();
  const theme = useTheme();

  const viewport = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModel, setModel] = useState(false);

  const logout = () => {
    setModel(true);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate(backofficeRoutes.login);
  };

  const handleCancleModel = () => {
    setModel(false);
  };

  const menuItems = [
    {
      name: 'Logout',
      onclick: logout,
    },
  ];

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Box
      sx={{ ...organizationlayout_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ ...organizationlayout_style.appBarSx, ...appBarStyle }}>
        <AppBar
          navItems={navItems}
          openDrawer={openDrawer}
          onOpen={handleDrawerOpen}
          onClose={handleDrawerClose}
          menuItems={menuItems}
          profileData={profileData}
          Organizationlogo={<HealthCirclesLogo />}
          organizationName="Health Circles Back Office"
          logo={logo}
          anchorOriginStyle={organizationlayout_style.anchorStyleSx}
          menuStyle={organizationlayout_style.menuSx}
          menuItemStyle={organizationlayout_style.menuItemSx}
          showNotification={false}
          version={
            localStorage.getItem(localStorageKeys.backOfficeVersion) ?? '0.0.0'
          }
        />
      </Box>

      <Box
        sx={{
          ...(viewport ? ' ' : organizationlayout_style.bodySx),
          ...bodyStyle,
        }}
      >
        <Box
          sx={
            viewport
              ? organizationlayout_style.responsiveBoxSx
              : organizationlayout_style.boxSx
          }
        >
          <SideBar navItems={navItems} />
        </Box>

        {/* Children */}
        <Box
          sx={{
            ...organizationlayout_style.childrenSx,
            ...childrenStyle,
          }}
          onClick={handleDrawerClose}
        >
          <Outlet />
        </Box>
        {openModel && (
          <LogoutModal
            isModalOpen={openModel}
            handleClose={handleCancleModel}
            handleDelete={handleLogout}
          />
        )}
      </Box>
    </Box>
  );
}

Layout.propTypes = {
  adminName: PropTypes.string,
  navItems: PropTypes.array,
  role: PropTypes.string,

  profileData: PropTypes.array,
  childrenStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  appBarStyle: PropTypes.object,
  profileTitle: PropTypes.string,
  Organizationlogo: PropTypes.object,
  logo: PropTypes.object,
  icon: PropTypes.object,
  children: PropTypes.object,
  sx: PropTypes.object,
};
