import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

import { filterTab_style } from './style';

function FilterTab(props) {
  const {
    className = '',
    rootStyle = {},
    filterList,
    handleChange,
    value,
    showCount,
    toggleStyle,
    toggleContainerSx,
    filterTabRootStyle,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...filterTab_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Tabs
        sx={{
          '& .MuiTabs-flexContainer': {
            display: 'flex',
            justifyContent: 'space-around',
            ...toggleContainerSx,
          },
        }}
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            height: '100%',
            top: 1, // Adjust the negative top margin to align the tab indicator
            zIndex: 0, // Set a lower zIndex for the tab indicator
            backgroundColor: '#007965 ',
            borderRadius: '10px',
            ...toggleStyle,
          },
        }}
      >
        {showCount
          ? filterList?.map((data, i) => (
              <Tab
                id={data?.id}
                key={i}
                label={
                  data?.count >= 0
                    ? `${data?.name}(${data?.count})`
                    : data?.name
                }
                disableFocusRipple
                disableRipple
                sx={{ ...filterTab_style.tabButtonSx, ...filterTabRootStyle }}
              />
            ))
          : filterList?.map((data, i) => (
              <Tab
                id={data?.id}
                key={i}
                label={data}
                disableFocusRipple
                disableRipple
                sx={{ ...filterTab_style.tabButtonSx, ...filterTabRootStyle }}
              />
            ))}
      </Tabs>
    </Box>
  );
}

FilterTab.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  filterList: PropTypes.any,
  handleChange: PropTypes.any,
  value: PropTypes.any,
  showCount: PropTypes.any,
  toggleStyle: PropTypes.any,
  toggleContainerSx: PropTypes.any,
  filterTabRootStyle: PropTypes.any,
};

export { FilterTab };
