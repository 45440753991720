/* eslint-disable no-lonely-if */
/* eslint-disable use-isnan */
/* eslint-disable prefer-const */
import { useMasters, useOrganization } from '@hc/store';
import { monthDiff, handleUploadFile, queryClient } from '@hc/utils';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
// import { useNavigate } from 'react-router-dom';
import {
  BackArrowIcon,
  Button,
  CloseIcon,
  DateRangePicker,
  Dropdown,
  Input,
  Label,
  Switch,
} from '../../../atoms';
import { addContractDrawer_style } from './style';

function AddContractDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    closeContractDrawer,
    ...rest
  } = props;
  // const navigate = useNavigate();
  const [saveBtn, setSaveBtn] = useState(false);4
  const {
    organizationState,
    clearContractData,
    upsertContract,
    getAllContractData,
    contractStateUpdate,
    loading,
  } = useOrganization((state) => ({
    getAllContractData: state.getAllContractData,
    clearContractData: state.clearContractData,
    upsertContract: state.upsertContract,
    organizationState: state.organizationState,
    contractStateUpdate: state.contractStateUpdate,
    loading: state.loading,
  }));

  const { contract } = organizationState;

  const { getPlanDurationType, mastersState } = useMasters((state) => ({
    getPlanDurationType: state.getPlanDurationType,
    mastersState: state.mastersState,
  }));

  const { planDurationType } = mastersState;

  // --------------- Is Valid check --------------
  const isValid = () => {
    const contractCopy = JSON.parse(JSON.stringify(contract));
    const errorCopy = contract?.error;
    let isValid = true;

    // checking organization_name

    if (!contractCopy?.contract_detail?.organization_name?.length > 0) {
      isValid = false;
      errorCopy.organization_name = 'Please enter the organization name';
    } else {
      errorCopy.organization_name = '';
    }

    // checking contract_start_date

    if (!contractCopy?.contract_detail?.contract_start_date) {
      isValid = false;
      errorCopy.contract_start_date = 'Please enter the contract start date';
    } else {
      errorCopy.contract_start_date = '';
    }

    // checking contract_end_date

    if (!contractCopy?.contract_detail?.contract_end_date) {
      isValid = false;
      errorCopy.contract_end_date = 'Please enter the contract end date';
    } else {
      errorCopy.contract_end_date = '';
    }

    // checking members

    if (!contractCopy?.contract_detail?.members?.length > 0) {
      isValid = false;
      errorCopy.members = 'Please enter the member count';
    } else {
      errorCopy.members = '';
    }

    // checking no_of_free_consultations

    if (!contractCopy?.contract_detail?.no_of_free_consultations?.length > 0) {
      isValid = false;
      errorCopy.no_of_free_consultations =
        'Please enter the number of free consultations';
    } else {
      errorCopy.no_of_free_consultations = '';
    }

    // checking basic_amount

    if (!contractCopy?.contract_detail?.basic_amount > 0) {
      isValid = false;
      errorCopy.basic_amount = 'Please enter the basic amount';
    } else {
      errorCopy.basic_amount = '';
    }

    if (contractCopy?.motivation_detail?.is_enabled) {
      // checking redemption_avail_for

      if (!contractCopy?.motivation_detail?.redemption_avail_for?.length > 0) {
        isValid = false;
        errorCopy.redemption_avail_for =
          'Please select the redemption avilable for type';
      } else {
        errorCopy.redemption_avail_for = '';
      }

      // checking first_place_point

      if (!contractCopy?.motivation_detail?.first_place_point?.length > 0) {
        isValid = false;
        errorCopy.first_place_point = 'Please enter the first place amount';
      } else {
        errorCopy.first_place_point = '';
      }

      // checking second_place_point

      if (!contractCopy?.motivation_detail?.second_place_point?.length > 0) {
        isValid = false;
        errorCopy.second_place_point = 'Please enter the second place amount';
      } else {
        errorCopy.second_place_point = '';
      }

      // checking third_place_point

      if (!contractCopy?.motivation_detail?.third_place_point?.length > 0) {
        isValid = false;
        errorCopy.third_place_point = 'Please enter the third place amount';
      } else {
        errorCopy.third_place_point = '';
      }
    }

    // checking contract_mou

    if (!contractCopy?.contract_file?.contract_mou?.filename?.length > 0) {
      isValid = false;
      errorCopy.contract_mou = 'Please upload the contract MOU';
    } else {
      errorCopy.contract_mou = '';
    }

    contractStateUpdate('', 'error', errorCopy);
    return isValid;
  };

  const handleOnchange = (subKey, key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        if (saveBtn) isValid();
        return;
      }
    }

    if (subKey !== 'contract_start_date' && subKey !== 'contract_end_date') {
      contractStateUpdate(subKey, key, value);
    } else {
      if (subKey === 'contract_start_date') {
        const curDate = new Date().toLocaleDateString();
        const startDate = new Date(value).toLocaleDateString();
        if (curDate <= startDate) {
          contractStateUpdate(subKey, key, value);
        } else {
          toast.error('Please select the current date or greater than..');
        }
      } else {
        if (subKey === 'contract_end_date') {
          const curDate = new Date();
          const endDate = new Date(value);
          if (curDate < endDate) {
            contractStateUpdate(subKey, key, value);
          } else {
            toast.error('Please select the greater than current date..');
          }
        }
      }
    }

    let totalChatAmount;

    if (
      key === 'contract_detail' ||
      key === 'motivation_detail' ||
      key === 'engagement_detail'
    ) {
      // --------------- Engagement amount------------
      if (
        (subKey === 'members'
          ? value?.length > 0
          : contract?.contract_detail?.members.length > 0) &&
        (subKey === 'members'
          ? value
          : contract?.contract_detail?.contract_start_date) &&
        (subKey === 'members'
          ? value
          : contract?.contract_detail?.contract_end_date)
      ) {
        const date1 = new Date(
          subKey === 'contract_start_date'
            ? value
            : contract?.contract_detail?.contract_start_date,
        );
        const date2 = new Date(
          subKey === 'contract_end_date'
            ? value
            : contract?.contract_detail?.contract_end_date,
        );
        const Difference_In_Time = date2.getTime() - date1.getTime();
        const dateCount = Difference_In_Time / (1000 * 3600 * 24) + 1;

        const whatsAppAmount = 0.5;

        const members = parseInt(
          subKey === 'members' ? value : contract?.contract_detail?.members,
          10,
        );

        const engagement_amount = whatsAppAmount * members * dateCount;

        contractStateUpdate(
          'engagement_amount',
          'engagement_detail',
          engagement_amount !== NaN
            ? Math.round(engagement_amount).toString()
            : 0,
        );

        // ---------Update chat amount--------------

        const monthDif = monthDiff(
          subKey === 'contract_start_date'
            ? value
            : contract?.contract_detail?.contract_start_date,
          subKey === 'contract_end_date'
            ? value
            : contract?.contract_detail?.contract_end_date,
        );

        const chatBaseAmount = 0.5;

        const count =
          subKey === 'question_count'
            ? value
            : contract?.contract_detail?.question_count;

        const chatCount = parseInt(count, 10) - 20;

        totalChatAmount =
          parseInt(count, 10) >= 20
            ? chatBaseAmount * chatCount * members * monthDif
            : 0;

        contractStateUpdate(
          'chat_ques_amount',
          'contract_detail',
          totalChatAmount !== NaN ? Math.round(totalChatAmount).toString() : 0,
        );

        // -----------Motivational amount ------------
        let motivational_amount = 0;

        if (
          (subKey === 'redemption_avail_for'
            ? value.length > 0
            : contract?.motivation_detail?.redemption_avail_for?.length > 0) &&
          (subKey === 'first_place_point'
            ? value.length > 0
            : contract?.motivation_detail?.first_place_point?.length > 0) &&
          (subKey === 'second_place_point'
            ? value.length > 0
            : contract?.motivation_detail?.second_place_point?.length > 0) &&
          (subKey === 'third_place_point'
            ? value.length > 0
            : contract?.motivation_detail?.third_place_point?.length > 0)
        ) {
          const total_redeem_amount =
            (subKey === 'first_place_point'
              ? parseInt(value, 10)
              : parseInt(contract?.motivation_detail?.first_place_point, 10)) +
            (subKey === 'second_place_point'
              ? parseInt(value, 10)
              : parseInt(contract?.motivation_detail?.second_place_point, 10)) +
            (subKey === 'third_place_point'
              ? parseInt(value, 10)
              : parseInt(contract?.motivation_detail?.third_place_point, 10));

          const redemption_avail_for =
            subKey === 'redemption_avail_for'
              ? value === 'Days'
                ? 1
                : value === 'Weeks'
                ? 7
                : value === 'Months'
                ? 30
                : 0
              : contract?.motivation_detail?.redemption_avail_for === 'Days'
              ? 1
              : contract?.motivation_detail?.redemption_avail_for === 'Weeks'
              ? 7
              : contract?.motivation_detail?.redemption_avail_for === 'Months'
              ? 30
              : 0;

          motivational_amount =
            (total_redeem_amount * dateCount) / redemption_avail_for;

          contractStateUpdate(
            'motivational_amount',
            'motivation_detail',
            motivational_amount !== NaN
              ? Math.round(motivational_amount).toString()
              : 0,
          );
        } else {
          contractStateUpdate('motivational_amount', 'motivation_detail', 0);
        }

        // ----------Total amout ---------
        if (
          (subKey === 'basic_amount'
            ? value?.length > 0
            : contract?.contract_detail?.basic_amount?.length > 0) &&
          !(subKey === 'is_enabled' && key === 'engagement_detail'
            ? value
            : contract?.engagement_detail?.is_enabled) &&
          !(subKey === 'is_enabled' && key === 'motivation_detail'
            ? value
            : contract?.motivation_detail?.is_enabled)
        ) {
          const total_amout =
            subKey === 'basic_amount'
              ? value
              : contract?.contract_detail?.basic_amount;
          contractStateUpdate(
            'total_contract_amount',
            'amount_detail',
            total_amout !== NaN
              ? Math.round(
                  parseInt(total_amout, 10) + totalChatAmount,
                ).toString()
              : 0,
          );
        } else if (
          (subKey === 'basic_amount'
            ? value?.length > 0
            : contract?.contract_detail?.basic_amount?.length > 0) &&
          (subKey === 'is_enabled' && key === 'engagement_detail'
            ? value
            : contract?.engagement_detail?.is_enabled) &&
          !(subKey === 'is_enabled' && key === 'motivation_detail'
            ? value
            : contract?.motivation_detail?.is_enabled)
        ) {
          const basic_amount = parseInt(
            subKey === 'basic_amount'
              ? value
              : contract?.contract_detail?.basic_amount,
            10,
          );

          const total_amout =
            basic_amount + engagement_amount + totalChatAmount;

          contractStateUpdate(
            'total_contract_amount',
            'amount_detail',
            total_amout !== NaN ? Math.round(total_amout).toString() : 0,
          );
        } else if (
          (subKey === 'basic_amount'
            ? value?.length > 0
            : contract?.contract_detail?.basic_amount?.length > 0) &&
          !(subKey === 'is_enabled' && key === 'engagement_detail'
            ? value
            : contract?.engagement_detail?.is_enabled) &&
          (subKey === 'is_enabled' && key === 'motivation_detail'
            ? value
            : contract?.motivation_detail?.is_enabled)
        ) {
          const basic_amount = parseInt(
            subKey === 'basic_amount'
              ? value
              : contract?.contract_detail?.basic_amount,
            10,
          );

          const total_amout =
            basic_amount + motivational_amount + totalChatAmount;
          contractStateUpdate(
            'total_contract_amount',
            'amount_detail',
            total_amout !== NaN ? Math.round(total_amout).toString() : 0,
          );
        } else if (
          (subKey === 'basic_amount'
            ? value?.length > 0
            : contract?.contract_detail?.basic_amount?.length > 0) &&
          (subKey === 'is_enabled' && key === 'engagement_detail'
            ? value
            : contract?.engagement_detail?.is_enabled) &&
          (subKey === 'is_enabled' && key === 'motivation_detail'
            ? value
            : contract?.motivation_detail?.is_enabled)
        ) {
          const basic_amount = parseInt(
            subKey === 'basic_amount'
              ? value
              : contract?.contract_detail?.basic_amount,
            10,
          );

          const total_amout =
            basic_amount +
            engagement_amount +
            motivational_amount +
            totalChatAmount;
          contractStateUpdate(
            'total_contract_amount',
            'amount_detail',
            total_amout !== NaN ? Math.round(total_amout).toString() : 0,
          );
        } else {
          contractStateUpdate('total_contract_amount', 'amount_detail', 0);
        }
      } else {
        contractStateUpdate('engagement_amount', 'engagement_detail', 0);
        contractStateUpdate('motivational_amount', 'motivation_detail', 0);
        contractStateUpdate('total_contract_amount', 'amount_detail', 0);
      }
    }
    if (saveBtn) isValid();
  };

  // ---------- file  upload -----------

  const handleFileUpload = async (e) => {
    let fileList = e?.target?.files;
    if (fileList.length > 0) {
      const data = await handleUploadFile({ 0: fileList[0], length: 1 });
      contractStateUpdate('contract_mou', 'contract_file', data[0]);
    }
  };

  const onCreate = async () => {
    const result = isValid();
    setSaveBtn(true);
    if (result) {
      setSaveBtn(false);
      queryClient.invalidateQueries({
        queryKey: ['contracts-add'],
      });
      const response = await upsertContract();
      if (response === 200) {
        clearContractData();
        queryClient.invalidateQueries({
          queryKey: ['contracts-get'],
        });
        await getAllContractData('', 10, 0);
        closeContractDrawer();
      }
    }
  };

  const onCancel = () => {
    clearContractData();
    closeContractDrawer();
  };

  const getIntialData = async () => {
    await getPlanDurationType(true);
  };

  useEffect(() => {
    getIntialData();
    contractStateUpdate(
      'organization_name',
      'contract_detail',
      organizationState?.organization?.name,
    );
  }, []);

  // console.log(contract,"asdaskjfh")

  return (
    <Box
      sx={{
        ...addContractDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={addContractDrawer_style.topbar} mb={1}>
        <Box sx={addContractDrawer_style.flexSX}>
          <BackArrowIcon
            onClick={() => onCancel()}
            rootStyle={{ cursor: 'pointer' }}
          />
          <Typography>Contract Details / Add New Contract</Typography>
        </Box>
        <Box sx={addContractDrawer_style.flexSX}>
          <Button
            sx={addContractDrawer_style.SaveButtonSx}
            onClick={() => onCancel()}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onCreate()}
            loading={loading}
            sx={addContractDrawer_style.cancleButtonSx}
          >
            Create Contract
          </Button>
        </Box>
      </Box>
      {/* BASIC DEATAILS */}
      <Stack sx={addContractDrawer_style.contractContainer}>
        <Grid container spacing={2.5} mb={1.5}>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="organizationEmail"
                isRequired
              >
                Organization Name
              </Label>
              <Input
                id="organizationName"
                textFieldStyle={addContractDrawer_style.inputFieldSx}
                onChange={(event) => {
                  handleOnchange(
                    'organization_name',
                    'contract_detail',
                    event.target.value,
                  );
                }}
                disabled
                isError={contract?.error?.organization_name?.length > 0}
                errorMessage={contract?.error?.organization_name}
                value={contract?.contract_detail?.organization_name ?? ''}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="Contract Start Date"
                isRequired
              >
                Contract Start Date
              </Label>
              <DateRangePicker
                // datatestid="startDate"
                startDate={contract?.contract_detail?.contract_start_date ?? ''}
                onChange={(val) => {
                  handleOnchange('contract_start_date', 'contract_detail', val);
                }}
                dateFormat="dd-MM-yyyy"
                customSx={{ backgroundColor: '#fff', borderColor: '#c5c5c5' }}
              />
              {contract?.error?.contract_start_date?.length > 0 && (
                <Typography fontSize={12} mx={1} color="	#ff3333">
                  {contract?.error?.contract_start_date}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="Contract End Date"
                isRequired
              >
                Contract End Date
              </Label>
              <DateRangePicker
                // datatestid="endDate"
                startDate={contract?.contract_detail?.contract_end_date ?? ''}
                onChange={(val) => {
                  handleOnchange('contract_end_date', 'contract_detail', val);
                }}
                dateFormat="dd-MM-yyyy"
                customSx={{ backgroundColor: '#fff', borderColor: '#c5c5c5' }}
              />
              {contract?.error?.contract_end_date?.length > 0 && (
                <Typography fontSize={12} mx={1} color="	#ff3333">
                  {contract?.error?.contract_end_date}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="Members"
                isRequired
              >
                Members
              </Label>
              <Input
                id="Members"
                isError={contract?.error?.members?.length > 0}
                errorMessage={contract?.error?.members}
                textFieldStyle={addContractDrawer_style.inputFieldSx}
                type="number"
                onChange={(event) => {
                  handleOnchange(
                    'members',
                    'contract_detail',
                    event.target.value,
                    5,
                  );
                }}
                value={contract?.contract_detail?.members ?? ''}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="noOfFreeConsulations"
                isRequired
              >
                No. Of Free Consultations
              </Label>
              <Input
                id="noOfFreeConsulations"
                type="number"
                textFieldStyle={addContractDrawer_style.inputFieldSx}
                onChange={(event) => {
                  handleOnchange(
                    'no_of_free_consultations',
                    'contract_detail',
                    event.target.value,
                    2,
                  );
                }}
                isError={contract?.error?.no_of_free_consultations?.length > 0}
                errorMessage={contract?.error?.no_of_free_consultations}
                value={
                  contract?.contract_detail?.no_of_free_consultations ?? ''
                }
                fullWidth
                endAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    Per member
                  </Typography>
                }
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="basic_amount"
                isRequired
              >
                Basic Contract Amount
              </Label>
              <Input
                id="basic_amount"
                type="number"
                textFieldStyle={addContractDrawer_style.inputFieldSx}
                onChange={(event) => {
                  handleOnchange(
                    'basic_amount',
                    'contract_detail',
                    event.target.value,
                    7,
                  );
                }}
                isError={contract?.error?.basic_amount?.length > 0}
                errorMessage={contract?.error?.basic_amount}
                value={contract?.contract_detail?.basic_amount ?? ''}
                fullWidth
                startAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    ₹
                  </Typography>
                }
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="questionCount"
                isRequired
              >
                Question count
              </Label>
              <Input
                id="question_count"
                type="number"
                textFieldStyle={addContractDrawer_style.inputFieldSx}
                onChange={(event) => {
                  handleOnchange(
                    'question_count',
                    'contract_detail',
                    event.target.value,
                    3,
                  );
                }}
                isError={contract?.error?.question_count?.length > 0}
                errorMessage={contract?.error?.question_count}
                value={contract?.contract_detail?.question_count ?? ''}
                fullWidth
                endAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    Per member
                  </Typography>
                }
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="chat_basic_amount"
                isRequired
              >
                Chat Basic Amount
              </Label>
              <Input
                id="chat_basic_amount"
                type="number"
                disabled
                textFieldStyle={addContractDrawer_style.inputFieldSx}
                value={contract?.contract_detail?.chat_ques_amount ?? ''}
                fullWidth
                startAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    ₹
                  </Typography>
                }
              />
            </Box>
          </Grid>
        </Grid>
        <Divider />

        {/* ENGAMENT PART */}

        <Box my={1.5}>
          <Box sx={addContractDrawer_style.AlignStraigntSX} my={1}>
            <Typography>Engagement settings</Typography>
            <Switch
              id="engagementToggle"
              onChange={(event) => {
                handleOnchange(
                  'is_enabled',
                  'engagement_detail',
                  event.target.checked,
                  7,
                );
              }}
              checked={contract?.engagement_detail?.is_enabled}
            />
            <Typography>Enabled</Typography>
          </Box>
          {contract?.engagement_detail?.is_enabled && (
            <Grid container mb={1}>
              <Grid itme xs={4}>
                <Label
                  rootStyle={addContractDrawer_style.labelSx}
                  htmlFor="Engagement"
                  isRequired
                >
                  Engagement Amount
                </Label>
                <Input
                  id="Engagement"
                  textFieldStyle={addContractDrawer_style.inputFieldSx}
                  disabled
                  type="number"
                  value={contract?.engagement_detail?.engagement_amount}
                  startAdornment={
                    <Typography
                      sx={{
                        color: '#B7B7B7',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      ₹
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <Divider />

        {/* MOTIVATION */}

        <Box sx={addContractDrawer_style.AlignStraigntSX} my={2}>
          <Typography>Motivation</Typography>
          <Switch
            id="motivationToggle"
            onChange={(event) => {
              handleOnchange(
                'is_enabled',
                'motivation_detail',
                event.target.checked,
              );
            }}
            checked={contract?.motivation_detail?.is_enabled}
          />
          <Typography>Enabled</Typography>
        </Box>
        {contract?.motivation_detail?.is_enabled && (
          <Grid container spacing={2} mb={1}>
            <Grid item xs={4}>
              <Box sx={addContractDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addContractDrawer_style.labelSx}
                  htmlFor="organizationEmail"
                  isRequired
                >
                  Redemption Available For
                </Label>
                <Dropdown
                  datatestid="redemption"
                  value={contract?.motivation_detail?.redemption_avail_for}
                  onChange={(event) => {
                    handleOnchange(
                      'redemption_avail_for',
                      'motivation_detail',
                      event.target.value,
                    );
                  }}
                  selectOption={planDurationType ?? []}
                  isError={contract?.error?.redemption_avail_for?.length > 0}
                  helperText={contract?.error?.redemption_avail_for}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={addContractDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addContractDrawer_style.labelSx}
                  htmlFor="first_place_point"
                  isRequired
                >
                  First Place Points
                </Label>
                <Input
                  id="first_place_point"
                  type="number"
                  textFieldStyle={addContractDrawer_style.inputFieldSx}
                  onChange={(event) => {
                    handleOnchange(
                      'first_place_point',
                      'motivation_detail',
                      event.target.value,
                      7,
                    );
                  }}
                  isError={contract?.error?.first_place_point?.length > 0}
                  errorMessage={contract?.error?.first_place_point}
                  value={contract?.motivation_detail?.first_place_point}
                  fullWidth
                  endAdornment={
                    <Typography
                      sx={{
                        color: '#B7B7B7',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      ₹
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={addContractDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addContractDrawer_style.labelSx}
                  htmlFor="second_place_point"
                  isRequired
                >
                  Second Place Points
                </Label>
                <Input
                  id="second_place_point"
                  type="number"
                  textFieldStyle={addContractDrawer_style.inputFieldSx}
                  onChange={(event) => {
                    handleOnchange(
                      'second_place_point',
                      'motivation_detail',
                      event.target.value,
                      7,
                    );
                  }}
                  isError={contract?.error?.second_place_point?.length > 0}
                  errorMessage={contract?.error?.second_place_point}
                  value={contract?.motivation_detail?.second_place_point}
                  fullWidth
                  endAdornment={
                    <Typography
                      sx={{
                        color: '#B7B7B7',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      ₹
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={addContractDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addContractDrawer_style.labelSx}
                  htmlFor="third_place_point"
                  isRequired
                >
                  Third Place points
                </Label>
                <Input
                  id="third_place_point"
                  type="number"
                  textFieldStyle={addContractDrawer_style.inputFieldSx}
                  onChange={(event) => {
                    handleOnchange(
                      'third_place_point',
                      'motivation_detail',
                      event.target.value,
                      7,
                    );
                  }}
                  isError={contract?.error?.third_place_point?.length > 0}
                  errorMessage={contract?.error?.third_place_point}
                  value={contract?.motivation_detail?.third_place_point}
                  fullWidth
                  endAdornment={
                    <Typography
                      sx={{
                        color: '#B7B7B7',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      ₹
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={addContractDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addContractDrawer_style.labelSx}
                  htmlFor="motivationAmount"
                  isRequired
                >
                  Motivational Amount
                </Label>
                <Input
                  id="motivationAmount"
                  disabled
                  startAdornment={
                    <Typography
                      sx={{
                        color: '#B7B7B7',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      ₹
                    </Typography>
                  }
                  // id="organizationEmail"
                  textFieldStyle={addContractDrawer_style.inputFieldSx}
                  value={contract?.motivation_detail?.motivational_amount}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {/* WELLNESSS */}
        {/* <Divider />
        <Box sx={addContractDrawer_style.AlignStraigntSX} mt={2}>
          <Typography>Wellness package customization</Typography>
          <Switch />
          <Typography>Enabled</Typography>
        </Box>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="organizationEmail"
                isRequired
              >
                Redemption Available For
              </Label>
              <Dropdown
                value=""
                onChange={() => {}}
                selectOption={[]}
                // isError={member?.error?.contract_id?.length > 0}
                // helperText={member?.error?.contract_id}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={addContractDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="organizationEmail"
                isRequired
              >
                First Place Points
              </Label>
              <Input
                id="organizationEmail"
                textFieldStyle={addContractDrawer_style.inputFieldSx}
                onChange={() => {}}
                isError
                errorMessage
                value=""
                fullWidth
                startAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    ₹
                  </Typography>
                }
              />
            </Box>
          </Grid>
        </Grid> */}
        <Divider />
        <Box mt={2}>
          <Typography>Total Contract Amount</Typography>{' '}
          <Grid container mb={1}>
            <Grid itme xs={4} mb={1.5}>
              <Label
                rootStyle={addContractDrawer_style.labelSx}
                htmlFor="Engagement"
                isRequired
              >
                Engagement Amount
              </Label>
              <Input
                id="totalAmount"
                textFieldStyle={addContractDrawer_style.inputFieldSx}
                disabled
                // isError
                // errorMessage
                value={contract?.amount_detail?.total_contract_amount ?? 0}
                startAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    ₹
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box sx={addContractDrawer_style.inputGroupSx} my={1.5}>
          <Label
            isRequired
            rootStyle={addContractDrawer_style.labelSx}
            htmlFor="Address"
          >
            Upload MOU
          </Label>
          <Box component="label" sx={addContractDrawer_style.UploadbtnSx}>
            <input
              hidden
              name="img"
              accept="application/pdf"
              multiple="multiple"
              type="file"
              id="uplodebtnv"
              onChange={(e) => handleFileUpload(e)}
            />
            <Typography
              color="#fff"
              fontSize={14}
              textAlign="center"
              alignSelf="center"
              fontWeight={500}
            >
              Upload
            </Typography>
          </Box>
          {contract?.contract_file?.contract_mou?.filename?.length > 0 && (
            <Box sx={addContractDrawer_style.linkWrapSx}>
              <Typography sx={addContractDrawer_style.linkSx}>
                {contract?.contract_file?.contract_mou?.filename ?? ''}
              </Typography>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  contractStateUpdate('contract_mou', 'contract_file', {})
                }
              >
                <CloseIcon rootStyle={{ color: '#F44F5A' }} />
              </Box>
            </Box>
          )}
          {contract?.error?.contract_mou?.length > 0 && (
            <Typography fontSize={12} mx={1} color="	#ff3333">
              {contract?.error?.contract_mou}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
}

AddContractDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  closeContractDrawer: PropTypes.func,
};

export { AddContractDrawer };
