/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { envConfig } from '@hc/config';
import { getStartOfDayToString, getTimeZoneFnc } from '@hc/dayjs';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const constructPlanData = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    const arr = data.map((val) => {
      return {
        value: val,
        name: val?.organization_name ?? '',
        id: val?.id ?? 0,
        members: val?.member_count ?? '',
        email: val?.email_id ?? '',
        phone: val?.mobile_no ?? '',
        created_at: val?.created_at ?? '',
        user_profile_id: val?.user_profile_id ?? '',
        user_id: val?.user_id ?? '',
        updated_at: val?.updated_at ?? '',
        role_id: val?.role_id ?? '',
        is_active: val?.is_active ?? false,
        statusId:
          val?.status_name === 'Active'
            ? 1
            : val?.status_name === 'In Active'
            ? 2
            : val?.status_name === 'Blocked'
            ? 3
            : 4,
        status: val?.status_name ?? '',
      };
    });
    return arr;
  }
};

export const useOrganization = create((set, get) => ({
  organizationState: {
    organizationStateListData: [],
    organizationListCount: '',
    organization: {
      createOrganization: true,
      id: '',
      name: '',
      members: '',
      email: '',
      mobileNumber: {},
      user_profile_id: '',
      user_id: '',
      address: '',
      created_at: '',
      updated_at: '',
      logo: {},
      is_active: true,
      status: 2,
      error: {
        name: '',
        email: '',
        mobile: '',
      },
    },
    contractList: [],
    contractListCount: '',
    members: {
      membersData: [],
      membersCount: '',
      contract_Count: [],
      member: {
        name: '',
        email: '',
        mobileNumber: '',
        country_code: '',
        contract_id: '',
        gender: '',
        error: {
          name: '',
          email: '',
          mobile: '',
          contract_id: '',
        },
      },
    },
    contract: {
      contract_detail: {
        organization_name: '',
        contract_start_date: '',
        contract_end_date: '',
        members: '',
        no_of_free_consultations: '',
        basic_amount: 0,
        question_count: 20,
        chat_ques_amount: 0,
      },
      engagement_detail: {
        is_enabled: null,
        engagement_amount: '',
      },
      motivation_detail: {
        is_enabled: null,
        redemption_avail_for: '',
        first_place_point: '',
        second_place_point: '',
        third_place_point: '',
        motivational_amount: 0,
      },
      amount_detail: {
        total_contract_amount: 0,
      },
      contract_file: {
        contract_mou: {},
      },
      error: {
        organization_name: '',
        question_count: '',
        contract_start_date: '',
        contract_end_date: '',
        members: '',
        no_of_free_consultations: '',
        basic_amount: '',
        redemption_avail_for: '',
        first_place_point: '',
        second_place_point: '',
        third_place_point: '',
        contract_mou: '',
      },
      downloadMOUFile: {
        file: '',
      },
    },
  },
  loading: false,
  saveLoading: false,

  clearContractData: () => {
    const { organizationState } = get();
    const { contract } = organizationState;
    set(() => ({
      organizationState: {
        ...organizationState,
        contract: {
          ...contract,
          contract_detail: {
            organization_name: '',
            contract_start_date: '',
            contract_end_date: '',
            members: '',
            no_of_free_consultations: '',
            basic_amount: 0,
            question_count: 20,
            chat_ques_amount: 0,
          },
          engagement_detail: {
            is_enabled: null,
            engagement_amount: '',
          },
          motivation_detail: {
            is_enabled: null,
            redemption_avail_for: '',
            first_place_point: '',
            second_place_point: '',
            third_place_point: '',
            motivational_amount: 0,
          },
          amount_detail: {
            total_contract_amount: 0,
          },
          contract_file: {
            contract_mou: {},
          },
          error: {
            organization_name: '',
            contract_start_date: '',
            contract_end_date: '',
            members: '',
            no_of_free_consultations: '',
            basic_amount: '',
            redemption_avail_for: '',
            first_place_point: '',
            second_place_point: '',
            third_place_point: '',
            contract_mou: '',
          },
          downloadMOUFile: {
            file: '',
          },
        },
      },
    }));
  },

  clearOrganizationData: () => {
    const { organizationState } = get();
    const { organization } = organizationState;
    set(() => ({
      organizationState: {
        ...organizationState,
        organization: {
          ...organization,
          createOrganization: true,
          id: '',
          name: '',
          members: '',
          email: '',
          mobileNumber: {},
          user_profile_id: '',
          user_id: '',
          address: '',
          created_at: '',
          updated_at: '',
          logo: {},
          is_active: true,
          status: 2,
          error: {
            name: '',
            email: '',
            mobile: '',
          },
        },
      },
    }));
  },

  clearMemberData: () => {
    const { organizationState } = get();
    const { members } = organizationState;
    const { member } = members;
    set({
      organizationState: {
        ...organizationState,
        members: {
          ...members,
          member: {
            ...member,
            name: '',
            email: '',
            mobileNumber: '',
            country_code: '',
            contract_id: '',
            gender: '',
          },
        },
      },
    });
  },

  getMemberCount: async () => {
    try {
      const { organizationState } = get();
      const { members } = organizationState;

      set({ loading: true });
      const { data } = await httpRequest(
        'get',
        `${envConfig.api_url}/contracts/member-count/get?organization_id=${organizationState?.organization?.id}`,
        {
          ...organizationState?.organization?.id,
        },
        true
      );
      if (data.status.code === 200) {
        set(() => ({
          loading: false,
          organizationState: {
            ...organizationState,
            members: {
              ...members,
              contract_Count: data.data,
            },
          },
        }));
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getAllorganizationData: async (searchValue, limit = 10, offset = 0) => {
    // debugger;
    try {
      const { organizationState } = get();
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getAllorganizationData'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/get-all-organization`,
            {
              search: searchValue,
              limit,
              offset,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        set(() => ({
          loading: false,
          organizationState: {
            ...organizationState,
            organizationStateListData:
              Array.isArray(data?.results) && data?.results?.length > 0
                ? constructPlanData(data?.results)
                : [],
            organizationListCount: data?.organization_count,
          },
        }));
      }
      set(() => ({ loading: false }));
      return status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  getAllOrganizationMembers: async (searchValue, limit, offset = 0) => {
    try {
      const { organizationState } = get();
      const { members } = organizationState;
      set({ loading: true });

      const data = await queryClient.fetchQuery({
        queryKey: ['organization-members-all'],
        queryFn: async () => {
          const data = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/organization/members/get?organization_id=${organizationState?.organization?.id}&search=${searchValue}&limit=${limit}&offset=${offset}`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      const result = data?.data?.data?.results?.map((tableData) => ({
        id: tableData?.id ?? '',
        fullName: tableData?.name ?? '',
        email: tableData?.email_id ?? '',
        membershipCode: tableData?.membership_code ?? '',
        phoneNumber: tableData?.mobile ?? '',
        organization_member_id: tableData?.organization_member_id ?? '',
        isActive: tableData?.is_active ?? '',
        status: tableData?.status ?? '',
        statusId: tableData?.status_id ?? '',
        createdAt: tableData?.created_at ?? '',
        updatedAt: tableData?.updated_at ?? '',
        user_profile_id: tableData?.user_profile_id ?? '',
        role_id: tableData?.role_id ?? '',
        user_id: tableData?.user_id ?? '',
      }));

      return set({
        loading: false,
        organizationState: {
          ...organizationState,
          members: {
            ...members,
            membersData: result,
            membersCount: data?.data?.data?.member_count,
          },
        },
      });
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.data?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  organizationStatusUpdate: async (payload) => {
    try {
      const payloadData = {
        organization_id: payload?.row?.id ?? 0,
        active_status: payload?.row?.is_active ? false : true,
      };

      const { data } = await queryClient.fetchQuery({
        queryKey: ['organization_status_update'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/organization/status/update`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? 'plan Status Updated Successfully'
        );
      }

      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  organizationMemberStatusUpdate: async (payload) => {
    try {
      const payloadData = {
        member_id: payload?.row?.organization_member_id ?? 0,
        active_status: payload?.row?.isActive ? false : true,
      };

      const { data } = await queryClient.fetchQuery({
        queryKey: ['organization_member_update'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/organization-members/status/update`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? 'plan Status Updated Successfully'
        );
      }

      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  resendInvite: async (payload) => {
    try {
      const payloadData = {
        user_id: payload?.user_id ?? '',
        user_profile_id: payload?.user_profile_id ?? '',
        user_role_id: payload?.role_id ?? 0,
      };
      set({ loading: true });

      const { status } = await queryClient.fetchQuery({
        queryKey: ['user-resend-invite'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url}/user/resend-invite`,
            {
              ...payloadData,
            },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        set({ loading: false });
        toast.success(status?.message);
      }

      return status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  deleteMember: async (payload) => {
    try {
      const payloadData = {
        org_member_id: payload?.organization_member_id ?? '',
        is_deleted: true,
      };
      set({ loading: true });

      const { status } = await queryClient.fetchQuery({
        queryKey: ['backoffice-members-delete'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/members/delete`,
            {
              ...payloadData,
            },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        set({ loading: false });
        toast.success(status?.message);
      }

      return status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  viewAndDownloadMOU: async (payload) => {
    try {
      set({ loading: true });
      const { organizationState } = get();
      const { contract } = organizationState;
      const { downloadMOUFile } = contract;
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['contracts-get-mou'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/contracts/get/mou?id=${payload?.id}`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      set({ loading: false });
      if (status?.code === 200) {
        set({
          loading: false,
          organizationState: {
            ...organizationState,
            contract: {
              ...contract,
              downloadMOUFile: {
                ...downloadMOUFile,
                file: data?.mou_file,
              },
            },
          },
        });
      }

      return data?.mou_file;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  upsertOrganization: async () => {
    try {
      const { organizationState } = get();
      const { organization } = organizationState;
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ saveLoading: true });
      const payloadData = {
        variables: organization?.createOrganization
          ? {
              organization: organization?.name ?? '',
              userId: tokenData?.user_profile_id ?? '',
              mobile: organization?.mobileNumber?.mobile ?? '',
              mobileCountryCode:
                organization?.mobileNumber?.mobileCode ?? '+91',
              emailId: organization?.email,
              address: organization?.address,
              logo: organization?.logo ?? {},
            }
          : {
              organization_id: organization?.id ?? 0,
              organization: organization?.name ?? '',
              address: organization?.address,
              logo: organization?.logo ?? {},
            },
      };

      const { data } = await queryClient.fetchQuery({
        queryKey: ['organization/sendInvite'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.auth_url}/user/organization/sendInvite`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        if (data?.status?.message === 'Oqrganization Already Exist') {
          toast.error(data?.status?.message);
          set({ saveLoading: false });
          return 201;
        }
        toast.success(
          data?.status?.message ?? organization?.id?.length > 0
            ? 'Organization Profile Updated Sucessfully'
            : 'Organization Profile Created Sucessfully'
        );
        set({ saveLoading: false });
        return 200;
      }
    } catch (error) {
      set({ saveLoading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  upsertContract: async () => {
    try {
      const { organizationState } = get();
      const { contract } = organizationState;

      set({ loading: true });

      const payloadData = {
        id: organizationState?.organization?.user_id,
        organization_id: parseInt(organizationState?.organization?.id, 10),
        mou_file: contract?.contract_file?.contract_mou?.downloadUrl,
        organization_name: contract?.contract_detail?.organization_name ?? '',
        contract_start_date: getStartOfDayToString(
          contract?.contract_detail?.contract_start_date,
          1,
          'YYYY-MM-DD'
        ),
        contract_end_date: getStartOfDayToString(
          contract?.contract_detail?.contract_end_date,
          1,
          'YYYY-MM-DD'
        ),
        members_count: parseInt(contract?.contract_detail?.members, 10),
        contract_amount: contract?.amount_detail?.total_contract_amount,
        no_of_free_consultation: parseInt(
          contract?.contract_detail?.no_of_free_consultations,
          10
        ),
        basic_contract_amount: contract?.contract_detail?.basic_amount ?? '',
        is_engagement_enabled: contract?.engagement_detail?.is_enabled ?? false,
        engagement_amount: contract?.engagement_detail?.is_enabled
          ? contract?.engagement_detail?.engagement_amount
          : '',
        is_motivation_enabled: contract?.motivation_detail?.is_enabled ?? false,
        redemption_available_for: contract?.motivation_detail?.is_enabled
          ? contract?.motivation_detail?.redemption_avail_for === 'Days'
            ? 1
            : contract?.motivation_detail?.redemption_avail_for === 'Weeks'
            ? 2
            : 3
          : 0,
        first_place_point: contract?.motivation_detail?.is_enabled
          ? contract?.motivation_detail?.first_place_point
          : '',
        second_place_point: contract?.motivation_detail?.is_enabled
          ? contract?.motivation_detail?.second_place_point
          : '',
        third_place_point: contract?.motivation_detail?.is_enabled
          ? contract?.motivation_detail?.third_place_point
          : '',
        motivation_amount: contract?.motivation_detail?.is_enabled
          ? contract?.motivation_detail?.motivational_amount
          : '',
        clientTimeZone: getTimeZoneFnc(),
        question_count: contract?.contract_detail?.question_count ?? '',
        chat_qa_amount: contract?.contract_detail?.chat_ques_amount ?? '',
      };

      const { data } = await queryClient.fetchQuery({
        queryKey: ['contracts-add'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/contracts/add`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        toast.success(data?.status?.message);
        set({ loading: false });
        return 200;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  upsertMember: async () => {
    const { organizationState } = get();
    const { members } = organizationState;
    const { member } = members;

    try {
      set({ loading: true });
      const data = {
        name: member?.name ?? '',
        organization_id: organizationState?.organization?.id ?? '',
        gender: member?.gender?.label ?? '',
        mobile_no: member?.mobileNumber?.mobile ?? '',
        mobile_code: member?.mobileNumber?.mobileCode ?? '',
        email: member?.email ?? '',
        contract_id: member?.contract_id ?? '',
        role_id: 5,
        status: 4,
      };

      const response = await queryClient.fetchQuery({
        queryKey: ['members/add'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.auth_url}/user/organization/members/add`,
            {
              ...data,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      // dashboard

      if (response?.data?.statusCode === '200') {
        toast.success(
          response?.data?.message ??
            `Your Member has been created successfully.`
        );
        set({ loading: false });
        return response;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getAllContractData: async (searchValue, limit, offset = 0) => {
    const { organizationState } = get();
    const { organization } = organizationState;
    try {
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['contracts-get'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/contracts/get?id=${organization?.id}&search=${searchValue}&offset=${offset}&limit=${limit}`,
            {},
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (status === 200) {
        let resArr = [];
        for (const object of data?.data?.contract_details) {
          const obj = {
            id: object?.id,
            contract_ID: object?.contract_id,
            contract_Period: object?.contract_period,
            purchased_On: object?.purchased_on,
            members: object?.contract_members,
            amount: object?.contract_amount,
            is_active: object?.status,
          };
          resArr.push(obj);
        }
        set(() => ({
          loading: false,
          organizationState: {
            ...organizationState,
            contractList: resArr,
          },
        }));
        return data;
      }
      set(() => ({
        loading: false,
      }));
    } catch (error) {
      set(() => ({
        loading: false,
      }));
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  handleorganizationChange: (key, value) => {
    const { organizationState } = get();
    const { organization } = organizationState;

    set({
      organizationState: {
        ...organizationState,
        organization: {
          ...organization,
          [key]: value,
        },
      },
    });
  },

  handleMemberChange: (key, value) => {
    const { organizationState } = get();
    const { members } = organizationState;
    const { member } = members;

    set({
      organizationState: {
        ...organizationState,
        members: {
          ...members,
          member: {
            ...member,
            [key]: value,
          },
        },
      },
    });
  },

  contractStateUpdate: (subKey, key, value) => {
    const { organizationState } = get();
    const { contract } = organizationState;
    if (key !== 'error') {
      set({
        organizationState: {
          ...organizationState,
          contract: {
            ...contract,
            [key]: {
              ...contract?.[key],
              [subKey]: value,
            },
          },
        },
      });
    } else {
      set({
        organizationState: {
          ...organizationState,
          contract: {
            ...contract,
            [key]: value,
          },
        },
      });
    }
  },
}));
