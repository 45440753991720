/* eslint-disable react/no-unstable-nested-components */
import { getDateFormat } from '@hc/dayjs';
import { UseOrganizationData } from '@hc/store/backoffice/organizationMaster';
import { UsePlanStatus } from '@hc/store/backoffice/planStatus';
import { UsePlanStatusMasterData } from '@hc/store/backoffice/planStatusMaster';
import { SearchField, SearchIcon, TransactionFilterIcon } from '@hc/ui/atoms';
import { MasterTable } from '@hc/ui/components/backoffice';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { PlanStatusFilterDrawer } from '@hc/ui/components/backoffice/planStatusFilterDrawer';
import { TransactionsCard } from '@hc/ui/components/backoffice/transactionsCard';
import { queryClient } from '@hc/utils';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Popper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BiChevronDown, BiSearch } from 'react-icons/bi';
import { PlanStatus_style } from './style';

export default function PlanStatusPage(props) {
  const { className = '', ...rest } = props;

  //   ------------------------LOCAL STATE---------------------------------
  const [drugDrawerOpen, setDrugDrawerOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [orgValue, setorgValue] = useState(null);
  const [dropDownOpen, setdropDownOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //   -------------------------STORE---------------------------------------

  const {
    getAllPlanStatusData,
    planStatusListData,
    planCardData,
    handlePlanChange,
    planStatusCount,
  } = UsePlanStatus((state) => ({
    getAllPlanStatusData: state.getAllPlanStatusData,
    planStatusListData: state.planStatusListData,
    planCardData: state.planCardData,
    handlePlanChange: state.handlePlanChange,
    planStatusCount: state.planStatusCount,
  }));

  const { getOrganizationData, OrganizationData } = UseOrganizationData(
    (state) => ({
      getOrganizationData: state.getOrganizationData,
      OrganizationData: state.OrganizationData,
    }),
  );

  const { planFilterStatus, planFilterType } = UsePlanStatus((state) => ({
    planFilterStatus: state.planFilterStatus,
    planFilterType: state.planFilterType,
  }));

  const { getPlanStatusData } = UsePlanStatusMasterData((state) => ({
    getPlanStatusData: state.getPlanStatusData,
  }));
  //   -------------------------DropDown Data and Handle Change-----------------------------------------
  // const selection = [
  //   {
  //     value: 'All',
  //     label: 'All Users',
  //   },
  //   {
  //     value: 'user',
  //     label: 'Non-organization users',
  //   },
  // ];

  console.log(OrganizationData, 'OrganizationData');

  const [select, setSelect] = React.useState(OrganizationData[0]?.value);
  const [selectedLabel, setSelectedLabel] = useState(
    OrganizationData[0]?.label,
  );
  // const handleChange = async (value) => {
  //   if (value.value === 'user') {
  //     handlePlanChange('orgSearch', value.value);
  //     setSelect(value.value);
  //     setdropDownOpen(false);
  //   } else if (value.value === 'All') {
  //     setSelect(value.value);
  //     handlePlanChange('orgSearch', '');
  //   }
  //   queryClient.invalidateQueries({
  //     queryKey: ['planstatusData'],
  //   });
  //   await getAllPlanStatusData('');
  // };
  const handleOnSelectClose = (e) => {
    if (e.target.classList[0] === 'MuiBackdrop-root') {
      setdropDownOpen(false);
    }
  };
  // -----------------------------Pagination State ---------------------------

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });
    await getAllPlanStatusData(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });
    await getAllPlanStatusData(search, rowsPerPage, offset);
  };

  // -----------------------------AutoComplete State Management-----------------------------------

  const handleAutoComplete = async (value) => {
    if (value.value === 'user') {
      handlePlanChange('orgSearch', value.value);
      setSelect(value.value);
      setSelectedLabel(value.label);
      setdropDownOpen(false);
    } else if (value.value === 'All') {
      setSelect(value.value);
      setSelectedLabel(value.label);
      handlePlanChange('orgSearch', '');
      setdropDownOpen(false);
    } else {
      setSelect('All');
      setSelectedLabel(value.label);
      handlePlanChange('orgSearch', value.value);
      setorgValue(value);
      setdropDownOpen(false);
      setorgValue(null);
    }

    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });

    await getAllPlanStatusData('');
  };

  const handlerRenderOrg = (value) => {
    setorgValue(value);
  };

  // ----------------------------------------Drawer Cl;ose-----------------------------
  const closeDrugMasterDrawer = () => {
    setDrugDrawerOpen(false);
  };

  // ----------------------------TABLE DATA AND HIGHLIGHTER--------------------------------------------
  const statusHighLighter = (value) => {
    return {
      backgroundColor:
        value === 'Publish'
          ? '#E8FCF0'
          : value === 'Draft'
          ? '#FCF8E8 '
          : value === 'pending'
          ? '#F5F5F5   '
          : '#fff',
      color:
        value === 'Publish'
          ? '#4CAF50'
          : value === 'Draft'
          ? '#FACC15'
          : value === 'pending'
          ? '#5C6266 '
          : '#0E1824',
    };
  };

  const columns = [
    {
      field: 'user_name',
      headerName: 'User Name',
      width: 200,
    },
    {
      field: 'requested_date',
      headerName: 'Plan request date',
      width: 180,
      valueGetter: (params) =>
        getDateFormat(params?.row?.requested_date, 'DD/MM/YYYY'),
    },

    {
      field: 'plan_type',
      headerName: 'Plan type',
      width: 180,
    },

    {
      field: 'plan_status_name',
      headerName: 'Plan Status',
      width: 180,
    },
    {
      field: 'mind',
      headerName: 'Mind Status',
      width: 180,
      renderCell: (params) => (
        <Box sx={{ cursor: 'pointer' }}>
          <Typography sx={PlanStatus_style.expertfontSx}>
            {params?.row?.mindExpertName}
          </Typography>
          <Tooltip
            placement="bottom-start"
            title={`Last updated ${getDateFormat(
              params?.row?.mindLastUpdate,
              'lll',
            )}`}
            arrow
          >
            <Typography
              sx={{
                ...PlanStatus_style.statusTypo,
                ...statusHighLighter(params?.row?.mind),
              }}
            >
              {params?.row?.mind}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'body',
      headerName: 'Body Status',
      width: 180,
      renderCell: (params) => (
        <Box sx={{ cursor: 'pointer' }}>
          <Typography sx={PlanStatus_style.expertfontSx}>
            {params?.row?.bodyExpertName}
          </Typography>
          <Tooltip
            placement="bottom-start"
            title={`Last updated ${getDateFormat(
              params?.row?.bodyLastUpdate,
              'lll',
            )}`}
            arrow
          >
            <Typography
              sx={{
                ...PlanStatus_style.statusTypo,
                ...statusHighLighter(params?.row?.body),
              }}
            >
              {params?.row?.body}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'food',
      headerName: 'Food Status',
      width: 170,
      renderCell: (params) => (
        <Box sx={{ cursor: 'pointer' }}>
          <Typography sx={PlanStatus_style.expertfontSx}>
            {params?.row?.foodExpertName}
          </Typography>
          <Tooltip
            placement="bottom-start"
            title={`Last updated ${getDateFormat(
              params?.row?.foodLastUpdate,
              'lll',
            )}`}
            arrow
          >
            <Typography
              sx={{
                ...PlanStatus_style.statusTypo,
                ...statusHighLighter(params?.row?.food),
              }}
            >
              {params?.row?.food}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
  ];

  // -------------------TABLE DATA SEARCH------------------------------------

  const handleSearchFnc = async (newData) => {
    setSearch(newData);
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });

    await getAllPlanStatusData(newData);
  };

  const filterDrawerOpen = () => {
    UsePlanStatus.setState({
      planStatus: planFilterStatus,
      planType: planFilterType,
    });
    setDrugDrawerOpen(true);
  };

  //   --------------------------------INITIAL FETCH-------------------------------

  const initialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });
    await getAllPlanStatusData('');
    getOrganizationData();
    getPlanStatusData();
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      p={3}
      sx={{
        overflow: 'scroll',
        backgroundColor: '#F8F8F9',
        height: '92vh',
      }}
    >
      <Box>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {' '}
          <Typography sx={PlanStatus_style.statsFont}>Stats</Typography>
          <Box display="flex">
            <Typography
              mt={2}
              ml={4}
              fontSize={14}
              fontWeight={500}
              sx={{ cursor: 'pointer' }}
              color="#007965"
              onClick
            >
              {selectedLabel}
            </Typography>
            <Select
              id="select"
              value={select}
              onClose={(e) => handleOnSelectClose(e)}
              open={dropDownOpen}
              onOpen={() => setdropDownOpen(true)}
              MenuProps={{
                PaperProps: {
                  sx: { ...PlanStatus_style.selectPaperPropsSx },
                },
              }}
              IconComponent={BiChevronDown}
              sx={PlanStatus_style.selectMenuSx}
            >
              {/* {selection?.map((val, i) => (
              <MenuItem
                key={i}
                onClick={() => handleChange(val)}
                value={val?.value}
                sx={PlanStatus_style.menuListSx}
              >
                {select === val?.value ? <TickIcon /> : null}
                &nbsp; {val?.label}
              </MenuItem>
            ))} */}
              <Stack sx={{ height: '250px', overflow: 'hidden' }}>
                {/* <Divider sx={{ my: 1 }} /> */}
                <Box px={2}>
                  <Autocomplete
                    // data-testId='search2'
                    getOptionLabel={(option) => option?.label}
                    value={orgValue}
                    onChange={(e, value) => handleAutoComplete(value)}
                    open
                    popupIcon=""
                    sx={{ width: 300 }}
                    disablePortal
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          height: '10px',
                          ...PlanStatus_style.textFieldSx,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <Typography
                          data-testId={`plan-${option?.label.replaceAll(
                            ' ',
                            '-',
                          )}`}
                          {...props}
                          sx={PlanStatus_style.dropdownTypoSx}
                          onChange={(e, option) => handlerRenderOrg(option)}
                        >
                          {' '}
                          {option?.label}
                        </Typography>
                      );
                    }}
                    PopperComponent={({ style, ...props }) => (
                      <Popper
                        popperOptions={{
                          placement: 'bottom-start',
                        }}
                        {...props}
                        sx={{
                          ...style,
                          '& ::-webkit-scrollbar': {
                            display: 'none',
                          },
                          '& .MuiPaper-root': {
                            mt: 0.5,
                            pb: 0.5,
                            height: '190px',
                            overflow: 'scroll',
                          },
                        }}
                      />
                    )}
                    options={OrganizationData}
                  />
                </Box>
              </Stack>
            </Select>
          </Box>
        </Stack>
        <Grid container spacing={2}>
          {planCardData.map((data, i) => (
            <Grid item xs={3} key={i} height="100%">
              {' '}
              <TransactionsCard
                percentage={data?.percentage?.slice(1)}
                description={data?.description}
                amount={data?.amount}
                positive={
                  Number(data?.percentage?.slice(0, -1)) > 0 ? true : false
                }
                showPercentage={data.percentage ? true : false}
                colorCode={data?.color ?? '#007965'}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={4}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box>
            <Typography sx={PlanStatus_style.statsFont}>Plan Status</Typography>
          </Box>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <SearchField
              searchFieldStyle={PlanStatus_style.searchFieldSx}
              placeholder="Search by User or Expert Name"
              onSearch={search}
              setOnSearch={handleSearchFnc}
              startAdornment={
                <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                  <BiSearch sx={{ fontSize: '14px', width: '14px' }} />
                </IconButton>
              }
            />

            <Box
              data-testId="filter"
              sx={PlanStatus_style.filterContainerSx}
              onClick={filterDrawerOpen}
            >
              <TransactionFilterIcon />
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Box mt={3}>
        <MasterTable
          columns={columns}
          data={planStatusListData}
          customTableStyle={PlanStatus_style.dataTableCustomSx}
          hideFooter
          rootStyle={{ height: '68vh' }}
          rowHeight={60}
        />
        <Stack flexDirection="row" justifyContent="end" sx={{ width: '100%' }}>
          <Pagination
            totalCount={planStatusCount}
            onLimitChange={onLimitChange}
            onOffsetchange={onOffsetchange}
            offset={offset}
            rowsPerPage={rowsPerPage}
          />
        </Stack>
      </Box>
      <PlanStatusFilterDrawer
        drugDrawerOpen={drugDrawerOpen}
        closeDrugMasterDrawer={closeDrugMasterDrawer}
      />
    </Box>
  );
}

PlanStatusPage.propTypes = {
  className: PropTypes.string,
};
