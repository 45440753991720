export const PlanStatus_style = {
  statsFont: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#0E1824',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  dataTableCustomSx: {
    '& .MuiDataGrid-row': {
      borderBottom: '2px solid',
      borderColor: 'grey.400',
    },
    '& .MuiDataGrid-main': {
      border: '1px solid',
      borderColor: 'grey.400',
      bgcolor: '#FFFFFF',
      borderRadius: '6px',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid',
      borderColor: 'grey.400',
    },
  },
  expertfontSx: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 400,
    color: '#0E1824',
    pb: 0.4,
  },
  selectMenuSx: {
    fontSize: '14px',
    color: '#5C6266',
    fontWeight: 500,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
      color: '#007965',
    },
    '& .MuiSelect-icon': {
      color: '#007965',
    },
  },
  selectPaperPropsSx: { boxShadow: '0px 0px 4px 2px #E3E3E3' },
  menuListSx: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#0F0B11',
    textAlign: 'left',
    '&.MuiMenuItem-root.Mui-selected': {
      color: '#007965',
      backgroundColor: '#fff',
    },
    '&.MuiMenuItem-root:hover': {
      backgroundColor: '#fff',
    },
    '&.MuiMenuItem-root.Mui-selected:hover': {
      backgroundColor: '#fff',
    },
  },
  searchFieldSx: {
    width: '360px',
    color: '#f00',
    '& .MuiOutlinedInput-root': {
      px: 0,
      pt: 0.7,
      backgroundColor: 'tranparent',
      '& fieldset': {
        borderRadius: '6px',
        border: '1.5px solid',
        borderColor: '#7B8894',
      },
      '&:hover fieldset': {
        borderColor: '#7B8894',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#7B8894',
      },
    },
    ' & .MuiOutlinedInput-input': {
      color: 'text.primary',
      fontSize: '14px',
      pt: 0.5,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      px: 2,
      height: '46px',
      border: 'none',
    },
    '&.MuiFormControl-root': {
      backgroundColor: '#fff',
      pb: '4px',
    },
  },
  filterContainerSx: {
    background: ' #FFFFFF ',
    border: '1.5px solid #007965',
    borderRadius: '6px',
    width: '39px',
    height: '39px',
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
  },
  textFieldSx: {
    borderColor: 'border.main',
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 600,
      height: '6px',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '6px !important',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.hover',
      },
    },
  },
  statusTypo: {
    px: 0.4,
    width: 'min-content',
    py: 0,
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 400,
  },
  dropdownTypoSx: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#0F0B11',
    py: 1,
  },
};
