export const backoffice_stepper_style = {
  contentWrapSx: {
    '&::before': {
      content: '""',
      // backgroundColor: 'purple.300',
      color: '#747474',
      // padding:'10px',
      // fontSize:'16px',
      position: 'absolute ',
      borderLeft: '2px dashed  !important',
      height: '100%',
      left: '11px',
      margin: '3px 1px',
    },
    '&:last-child': {
      '&: before': {
        borderLeft: '0px !important',
        content: '""',
      },
    },
    display: 'grid',
    position: 'relative',
  },
  completedSx: {
    color: 'green',
  },
  stepNumberSx: {
    borderRadius: '50%',
    color: 'mbf.main',
    fontWeight: '700',
    fontSize: '10px',
    position: 'absolute ',
    textAlign: 'center',
    margin: '1px 1px',
    height: '19px',
    width: '19px',
  },
  stepPointsSx: {
    marginLeft: '12%',
  },
  stepPointsHeadingSx: {
    // color: 'text.primary',
    // fontWeight: '600',
    // fontSize: '16px',
    fontSize: '12px',
    borderRadius: '6px',
    padding: '4px 8px',
    width: 'fit-content',
  },
  doctorNameSx: {
    fontSize: '14px',
    marginTop: '14px',
    marginBottom: '4px',
    color: '#262C33',
    fontWeight: 600,
  },
  dateTimeSx: {
    fontSize: '12px',
    color: '#262C33',
    marginBottom: '20px',
  },
};
