import PropTypes from 'prop-types';
import { UsePlanStatusMasterData } from '@hc/store/backoffice/planStatusMaster';
import { Button, Drawer, Label, ToggleButton } from '@hc/ui/atoms';
import { Box } from '@mui/material';
import { UsePlanStatus } from '@hc/store/backoffice/planStatus';
import { queryClient } from '@hc/utils';
import { planStatusFilterDrawer_style } from './style';

function PlanStatusFilterDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    drugDrawerOpen = false,
    closeDrugMasterDrawer = () => false,
    ...rest
  } = props;

  const {
    getAllPlanStatusData,
    handlePlanChange,
    planStatus,
    planType,
    clearPlanFilter,
    loading,
  } = UsePlanStatus((state) => ({
    getAllPlanStatusData: state.getAllPlanStatusData,
    handlePlanChange: state.handlePlanChange,
    planStatus: state.planStatus,
    planType: state.planType,
    clearPlanFilter: state.clearPlanFilter,
    loading: state.loading,
  }));

  const { PlanStatusData } = UsePlanStatusMasterData((state) => ({
    PlanStatusData: state.PlanStatusData,
  }));

  const handlePlanStatusChange = (key, value) => {
    handlePlanChange(key, value);
  };
  const handlePlanTypeChange = (key, value) => {
    handlePlanChange(key, value);
  };

  const HandleSubmit = async () => {
    UsePlanStatus.setState({
      planFilterStatus: planStatus,
      planFilterType: planType,
    });
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });
    await getAllPlanStatusData('');
    closeDrugMasterDrawer();
  };

  const HandleDrawerClose = () => {
    clearPlanFilter();
  };

  const Plantype = [
    { value: '', name: 'All' },
    { value: 'New', name: 'New' },
    { value: 'Renewal', name: 'Renewal' },
  ];

  return (
    <Box
      sx={{
        ...planStatusFilterDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {drugDrawerOpen && (
        <Drawer
          show={drugDrawerOpen}
          onCloseDrawer={closeDrugMasterDrawer}
          anchor="right"
          isCloseIconRequired
          header="Plan status filter"
          headerStyle={planStatusFilterDrawer_style.headerSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                position: 'absolute',
                inset: 'auto 0 0 0',
                mb: 3,
                px: 2,
              }}
            >
              <Button
                buttonStyle={planStatusFilterDrawer_style.SaveButtonSx}
                // onClick={() => upsertDrugMaster()}
                loading={loading}
                onClick={() => HandleSubmit()}
              >
                Apply Filter
              </Button>
              <Button
                buttonStyle={planStatusFilterDrawer_style.cancleButtonSx}
                onClick={HandleDrawerClose}
                disabled={loading}
              >
                Reset
              </Button>
            </Box>
          }
        >
          <Box sx={planStatusFilterDrawer_style.boxRootSx}>
            {/*  ----------------plan TYPE toggle  ------------- */}
            <Box sx={planStatusFilterDrawer_style.inputGroupSx}>
              <Label
                rootStyle={planStatusFilterDrawer_style.labelSx}
                htmlFor="SelectType"
                isRequired
              >
                Plan type
              </Label>
              <ToggleButton
                RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                options={Plantype}
                value={planType}
                buttonactiveStyle={
                  planStatusFilterDrawer_style.togglebuttonactiveSX
                }
                buttoninactiveStyle={
                  planStatusFilterDrawer_style.togglebuttoninactiveSX
                }
                onChange={(e) => {
                  handlePlanTypeChange('planType', e);
                }}
              />
            </Box>

            {/* ----------plan status TOGGLE------------------ */}

            <Box sx={planStatusFilterDrawer_style.inputGroupSx}>
              <Label
                rootStyle={planStatusFilterDrawer_style.labelSx}
                htmlFor="SelectType"
                isRequired
              >
                Plan Status
              </Label>
              <ToggleButton
                RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                options={PlanStatusData}
                value={planStatus}
                buttonactiveStyle={
                  planStatusFilterDrawer_style.togglebuttonactiveSX
                }
                buttoninactiveStyle={
                  planStatusFilterDrawer_style.togglebuttoninactiveSX
                }
                onChange={(e) => {
                  handlePlanStatusChange('planStatus', e);
                }}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

PlanStatusFilterDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PlanStatusFilterDrawer };
