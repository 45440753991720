/* eslint-disable no-sparse-arrays */
// import { envConfig } from '@hc/config';
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructPlanCradData = (data) => {
  if (Object.keys(data).length > 0) {
    return [
      {
        amount: data?.Count,
        percentage: '',
        description: 'Number of Users',
        color: '#007965',
      },
      {
        amount: data?.renewal,
        percentage: '',
        description: 'Renewal plans',
        color: '#007965',
      },
      {
        amount: data?.newPlan,
        percentage: '',
        description: 'New plans',
        color: '#007965',
      },
      {
        amount: data?.underReview,
        percentage: '',
        description: 'Under expert review',
        color: '#FACC15',
      },
    ];
  }
};

export const constructPlanTableData = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    const arr = data.map((val) => {
      return {
        value: val,
        id: val?.mbf_user_plan_id ?? 0,
        plan_id: val?.mbf_user_plan_id ?? 0,
        user_name: val?.mbf_user_name ?? '',
        requested_date: val?.plan_requested_date ?? false,
        plan_status_name: val?.plan_status_name ?? '',
        plan_type: val?.plan_type ?? '',
        mind: val?.statusData[0]?.status_name ?? 'pending',
        mindExpertName: val?.statusData[0]?.expert_name ?? '',
        mindLastUpdate: val?.statusData[0]?.updated_at ?? '',
        body: val?.statusData[1]?.status_name ?? 'pending',
        bodyExpertName: val?.statusData[1]?.expert_name ?? '',
        bodyLastUpdate: val?.statusData[1]?.updated_at ?? '',
        food: val?.statusData[2]?.status_name ?? 'pending',
        foodExpertName: val?.statusData[2]?.expert_name ?? '',
        foodLastUpdate: val?.statusData[2]?.updated_at ?? '',
        statusData: val?.statusData,
      };
    });
    return arr;
  }
};

export const UsePlanStatus = create((set, get) => ({
  planStatusListData: [],
  planCardData: [],
  planStatusCount: 0,
  planStatus: 0,
  planType: '',
  planFilterStatus: 0,
  planFilterType: '',
  orgSearch: '',
  loading: null,
  error: null,
  isIamValidToSave: () => {
    const { activity } = get();
    const activityCopy = JSON.parse(JSON.stringify(activity));
    let isValid = true;
    const error = activityCopy?.error;
    // checking drugName
    if (!activity?.activity_name) {
      isValid = false;
      error.activity_name = 'Please enter your activity name';
    } else {
      error.activity_name = '';
    }
    // checking time
    if (!activity?.category_id) {
      isValid = false;
      error.category_id = 'Please select category type';
    } else {
      error.category_id = '';
    }
    // checking when
    if (!activity?.goal_id) {
      isValid = false;
      error.goal_id = 'Please select goal type';
    } else {
      error.goal_id = '';
    }

    if (!activity?.activity_time) {
      isValid = false;
      error.activity_time = 'Please select activity time';
    } else {
      error.activity_time = '';
    }
    set({
      activity: {
        ...activity,
        error,
      },
    });

    return isValid;
  },

  // GET ALL ACTIVITY TEMPLATE DATA
  getAllPlanStatusData: async (search = '', limit = 10, offset = 0) => {
    const { planStatus, planType, orgSearch } = get();
    try {
      set({ loading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['planstatusData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/mbf-plan-status-detail`,
            {
              search,
              limit,
              offset,
              planStatus,
              planType,
              orgSearch,
            },
            true,
          );

          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          loading: false,
          planStatusListData:
            Array.isArray(data?.tableData) && data?.tableData?.length > 0
              ? constructPlanTableData(data?.tableData)
              : [],
          planCardData:
            Object.keys(data).length > 0
              ? constructPlanCradData({
                  Count: data?.totalCount,
                  renewal: data?.renewalPlanCount,
                  newPlan: data?.newPlanCount,
                  underReview: data?.underExpertReviewCount,
                })
              : [],
          planStatusCount: data?.totalCount,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  handlePlanChange: (key, value) => {
    set({
      [key]: value,
    });
  },

  clearPlanFilter: () => {
    set({
      planStatus: 0,
      planType: '',
    });
  },
}));
